import {useAppDispatch} from '../../../store';
import {useParams} from "react-router-dom";
import React, {useCallback, useEffect, useState} from 'react';
import {
  GetStratPillarListUsingGETPivotModeEnum,
  JsonStratCvb,
  JsonStratCvbStatusEnum,
  JsonStratEnabler,
  JsonStratEnablerStatusEnum,
  JsonStratInitiative,
  JsonStratInitiativeStatusEnum,
  JsonStratOnepagerStatusEnum,
  JsonStratPillar,
  JsonStratPillarStatusEnum,
  JsonStratSuccessLook,
  JsonStratSuccessLookStatusEnum
} from '../../../generated-api';
import PageHeader from '../../../components/layout/PageHeader';
import {Container, Grid} from '@mui/material';
import {useAppTranslation} from '../../../services/i18n';
import {
  addOnepagerEntity,
  deleteOnepagerEntity,
  fetchComments,
  fetchOnePager,
  fetchSerieOnePager,
  fetchStrategyContext,
  saveOnePager,
  setOnepagerActiveIndex
} from "../../../store/strategy";
import {useSelector} from "react-redux";
import {
  selectAuthUser,
  selectStrategyContext,
  selectStrategyOnePager, selectStrategyOnePagerActiveIndex,
  selectStrategyOnePagerComments,
  selectStrategyOnePagerEditable,
  selectStrategyParentOnePager,
  selectStrategyRootOnePager,
  selectStrategyUserOnePager
} from "../../../store/selectors";
import {addMessage} from "../../../store/localApp";
import OnePagerRow from "./OnePagerRow";
import {OnepagerEntityIndex, OnepagerEntityType} from "../../../model/onepager";
import OnePagerEditableText from "./OnePagerEditableText";
import OnePagerActionsContainer from "./OnePagerActionsContainer";
import stylesOnepager from '../../../assets/styles/onepager.module.css';
import styles from '../../../assets/styles/onepager.module.css';
import OnePagerButtons from "./OnePagerButtons";
import {CommonActionsType} from "../../../model/form";
import ParentPillarInfo from "./ParentPillarInfo";
import PillarStructureModal, {PillarStructurePivot} from "./PillarStructureModal";
import {parseArray} from "../../../helpers/onepager";
import HelpModal from "../../../components/layout/HelpModal";
import {CircleButton} from "../../../components/layout/CircleButton";
import CommentsContainer from "./CommentsContainer";
import {useInterval} from "../../../hooks/useInterval";
import HelpIcon from "@mui/icons-material/Help";
import {useModal} from "../../../services/modal";
import {fetchAuthLogout} from "../../../store/auth";

const MAX_PILLARS = 5;
const MAX_ENABLERS = 5;
const MAX_CVBS = 5;
const MAX_INITIATIVES = 5;
const MAX_SUCCESS_LOOKS = 5;

export enum OnePagerMode {
  Strategy = 'Strategy',
  Workzone = 'Workzone'
}

interface Props {
  mode?: OnePagerMode
}

const OnePagerPage = ({ mode }: Props) => {

  const t = useAppTranslation();

  const dispatch = useAppDispatch();
  const modal = useModal();

  const onePager = useSelector(selectStrategyOnePager);
  const userOnePager = useSelector(selectStrategyUserOnePager);
  const rootOnePager = useSelector(selectStrategyRootOnePager);
  const parentOnePager = useSelector(selectStrategyParentOnePager);

  const isEditable = useSelector(selectStrategyOnePagerEditable);
  const context = useSelector(selectStrategyContext);
  const comments = useSelector(selectStrategyOnePagerComments);
  const activeIndex = useSelector(selectStrategyOnePagerActiveIndex);

  const [structurePivot, setStructurePivot] = useState<PillarStructurePivot|undefined>(undefined);
  const [showComments, setShowComments] = useState<boolean>(false);
  const [help, setHelp] = useState<string | null>(null);

  const { id, code } = useParams();

  const isRootOnepager = onePager?.onepagerId === rootOnePager?.onepagerId;

  const ns = mode === OnePagerMode.Workzone ? 'workzone' :(isRootOnepager ? 'company' : 'team');

  const contextStyle = isRootOnepager ? {} : {
    backgroundColor: context?.company?.companyData?.backgroundColor || undefined,
    color: context?.company?.companyData?.textColor || 'white'
  }

  const loggedUser = useSelector(selectAuthUser);

  useEffect(() => {

    if (!loggedUser?.userId) {
      return;
    }

    if (id) {
      dispatch(fetchOnePager(+id))
          .then((result: any) => {
            if (result?.payload?.data) {
              dispatch(fetchStrategyContext(+result.payload.data.strategyId));
              dispatch(fetchComments(+id));
            }
          });
    } else if (mode === OnePagerMode.Workzone && !!code) {
      console.log('Fetch workzone onepager - ' + code);
      dispatch(fetchSerieOnePager( { clientGuid: code, loggedUserId: loggedUser?.userId }))
          .then((result: any) => {
              if (result.payload === undefined) {
                   dispatch(fetchAuthLogout());
              }
          });
    }

  }, [dispatch, id, mode, code, loggedUser]);

  const handleAction = useCallback(async (action: CommonActionsType, index: OnepagerEntityIndex) => {
    console.log({ action, index });
    switch (action) {
      case CommonActionsType.Create:
        dispatch(addOnepagerEntity(index));
        break;
      case CommonActionsType.Delete:
        const confirmResult = await modal.confirm({ title: t('onePager.messages.confirmation'), message: t('onePager.messages.deleteConfirm.' + index.entity) });
        if (confirmResult !== 'CONFIRM') {
          return;
        }
        dispatch(deleteOnepagerEntity(index));
        break;
      case CommonActionsType.Edit:
        dispatch(setOnepagerActiveIndex(index));
        break;
      case CommonActionsType.Down:
        if (index.entity === OnepagerEntityType.Pillar) {
          const pillar = onePager?.pillars[index.entityIndex!];
          setStructurePivot({
            pivotPillarId: pillar.pillarId,
            pivotMode: GetStratPillarListUsingGETPivotModeEnum.Down
          });
        }
        if (index.entity === OnepagerEntityType.Enabler) {
          const enabler = onePager?.enablers[index.entityIndex!];
          setStructurePivot({
            pivotEnablerId: enabler.enablerId,
            pivotMode: GetStratPillarListUsingGETPivotModeEnum.Down
          })
        }
        break;
      case CommonActionsType.Up:
        if (index.entity === OnepagerEntityType.Pillar) {
          const pillar = onePager?.pillars[index.entityIndex!];
          setStructurePivot({
            pivotPillarId: pillar.parentPillarId,
            pivotEnablerId: pillar.pivotEnablerId,
            pivotMode: GetStratPillarListUsingGETPivotModeEnum.Up
          });
        }
        break;
      case CommonActionsType.Help:
        setHelp('onePager.ns.' + ns + '.help.' + index.entity);
        break;
    }
  }, [onePager, dispatch, ns, modal, t]);

  const onSave = useCallback(async(status: JsonStratOnepagerStatusEnum | undefined, showMessage: boolean = true) => {
    if (onePager) {
      const saveResult = await dispatch(saveOnePager(status));
      if (showMessage) {
        if (saveResult) {
          dispatch(addMessage({
            severity: 'success',
            title: t('One-Pager byl uložen.'),
          }));
        } else {
          dispatch(addMessage({
            severity: 'error',
            title: t('Došlo k chybě při ukládání One-Pageru.'),
          }));
        }
      }
    }
  }, [onePager, dispatch, t])

  const handleSave = useCallback(async (status: JsonStratOnepagerStatusEnum, showMessage: boolean = true) => {
    // check open text area
    if (activeIndex) {
      await dispatch(setOnepagerActiveIndex(undefined));
      setTimeout(async () => {
        await onSave(status, showMessage);
      }, 0);
    } else {
      await onSave(status, showMessage);
    }
  }, [activeIndex, onSave, dispatch]);

  // autosaver
  useInterval(() => {
    onSave(undefined, false);
  }, 60000);

  let ambitionCompany = '';
  let ambitionTeam = '';
  if (mode === OnePagerMode.Workzone && onePager) {
      try {
        const result = JSON.parse(onePager.ambition);
        if (result?.length) {
          ambitionCompany = result[0];
          ambitionTeam = result[1];
        }
      } catch(e) {
      }
  }

  let enableNewPillar = !onePager?.pillars || onePager?.pillars?.filter((pillar: JsonStratPillar) => pillar.status === JsonStratPillarStatusEnum.Active).length < MAX_PILLARS;
  let enableNewEnabler = !onePager?.pillars || onePager?.enablers?.filter((enabler: JsonStratEnabler) => enabler.status === JsonStratEnablerStatusEnum.Active).length < MAX_ENABLERS;
  let enableNewCVBs = !onePager?.pillars || onePager?.cvbs?.filter((cvb: JsonStratCvb) => cvb.status === JsonStratCvbStatusEnum.Active).length < MAX_CVBS;

  return (
    <>
		{ !!onePager && !!rootOnePager &&
      <Container>
        <Grid container>
          <Grid item xs={12}>
            { /*
            <div style={{ color: '#fff' }}>PARENT</div>
            <div style={{ color: '#fff' }}>{ JSON.stringify(parentOnePager) }</div>
            <div style={{ color: '#fff' }}>{ isEditable ? 'EDITABLE' : 'READONLY' }</div>
            <div style={{ color: '#fff' }}>ROOT</div>
            <div style={{ color: '#fff' }}>{ JSON.stringify(rootOnePager) }</div>
            <div style={{ color: '#fff' }}>{ isRootOnepager ? 'ROOT-ONEPAGER' : 'NON-ROOT-ONEPAGER' }</div>
            <div style={{ color: '#fff' }}>CVBS</div>
            <div style={{ color: '#fff' }}>{ JSON.stringify((isRootOnepager ? onePager : rootOnePager).cvbs) }</div>
            <div style={{ color: '#fff' }}>ONEPAGER</div>
            <div style={{ color: '#fff' }}>{ <pre>{ JSON.stringify(onepager, null, 2) }</pre> }</div>
            */ }
            { mode === OnePagerMode.Strategy && <PageHeader title={`Onepager - ${userOnePager?.firstName} ${userOnePager?.lastName}`} showBack>
              <div style={{ display: 'flex', gap: '20px' }}>
                <div style={{ width: '32px '}}><CircleButton action={CommonActionsType.Help} onClick={() => setHelp('onePager.ns.' + ns + '.help.onepager')} /></div>
                <div style={{ width: '32px '}}><CircleButton action={CommonActionsType.Comment} withCount={comments.length} onClick={() => setShowComments((show) => !show)} /></div>
                <div style={{ border: '1px solid black', borderRadius: '5px', padding: '5px 10px', marginRight: '20px' }} className={stylesOnepager[onePager.status!]}>{ onePager.status }</div>
              </div>
            </PageHeader> }
          </Grid>
          <Grid item xs={12} md={showComments ? 9 : 12}>

            <OnePagerRow title={t('onePager.ns.' + ns + '.purpose')} index={{ entity: OnepagerEntityType.Purpose }} actions={[CommonActionsType.Help]} onAction={handleAction} sx={contextStyle} classx={styles.alfa}>
              <OnePagerActionsContainer index={{ entity: OnepagerEntityType.Purpose }} actions={(isEditable && isRootOnepager) ? [CommonActionsType.Edit] : []} onAction={handleAction}>
                <OnePagerEditableText index={{ entity: OnepagerEntityType.Purpose }} value={ !isRootOnepager ? rootOnePager.purpose : onePager.purpose } readonly={!isEditable || !isRootOnepager}/>
              </OnePagerActionsContainer>
            </OnePagerRow>

            { mode === OnePagerMode.Workzone &&
                <>
                    <OnePagerRow title={t('onePager.ns.' + ns + '.strategicAmbition')} index={{ entity: OnepagerEntityType.AmbitionCompany }} actions={[CommonActionsType.Help]} onAction={handleAction} sx={contextStyle} classx={styles.alfa}>
                      <OnePagerActionsContainer index={{ entity: OnepagerEntityType.AmbitionCompany }} actions={(isEditable && isRootOnepager) ? [CommonActionsType.Edit] : []} onAction={handleAction}>
                        <OnePagerEditableText index={{ entity: OnepagerEntityType.AmbitionCompany }} value={ ambitionCompany } readonly={!isEditable || !isRootOnepager}/>
                      </OnePagerActionsContainer>
                    </OnePagerRow>

                    <OnePagerRow title={t('onePager.ns.' + ns + '.teamStrategicAmbition')} index={{ entity: OnepagerEntityType.AmbitionTeam }} actions={[CommonActionsType.Help]} onAction={handleAction} classx={styles.beta}>
                      <OnePagerActionsContainer index={{ entity: OnepagerEntityType.AmbitionTeam }} actions={isEditable ? [CommonActionsType.Edit] : []} onAction={handleAction}>
                        <OnePagerEditableText index={{ entity: OnepagerEntityType.AmbitionTeam }} value={ ambitionTeam } readonly={!isEditable}/>
                      </OnePagerActionsContainer>
                    </OnePagerRow>

                    <OnePagerRow index={{ entity: OnepagerEntityType.Pillar }} classx={styles.omega}>
                      { onePager.pillars?.map((pillar: JsonStratPillar, pillarIndex: number) => {
                        const index = { entity: OnepagerEntityType.Pillar, entityIndex: pillarIndex };
                        const order = onePager.pillars?.reduce((acc: number, item: JsonStratPillar, orderIndex: number) => { return acc + ((item.status === JsonStratPillarStatusEnum.Active && orderIndex <= pillarIndex) ? 1 : 0) }, 0) || 1;
                        return pillar.status === JsonStratPillarStatusEnum.Active ?
                            <OnePagerActionsContainer index={index}>
                              <div className={stylesOnepager.pillarHeader}>{t('onePager.ns.' + ns + '.strategicPillar')} #{ order }
                                <span style={{ position: 'relative' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <HelpIcon style={{ cursor: 'pointer', paddingTop: '0px', position: 'absolute', top: '-3px', left: '5px' }} onClick={() => handleAction(CommonActionsType.Help, { entity: OnepagerEntityType.PillarHeader } )}/>
                                </span>
                              </div>
                            </OnePagerActionsContainer> : <></>;
                      })}
                    </OnePagerRow>

                    <OnePagerRow title={t('onePager.ns.' + ns + '.pillarCompany')} index={{ entity: OnepagerEntityType.PillarCompany }} actions={isEditable && enableNewPillar ? [CommonActionsType.Create, CommonActionsType.Help] : [CommonActionsType.Help]} onAction={handleAction} classx={styles.gamma}>
                      { onePager.pillars?.map((pillar: JsonStratPillar, pillarIndex: number) => {
                        const index = { entity: OnepagerEntityType.PillarCompany, entityIndex: pillarIndex };
                        const actions = [];
                        if (isEditable) {
                          actions.push(CommonActionsType.Delete);
                          actions.push(CommonActionsType.Edit);
                        }
                        const description = parseArray(pillar?.description!, 0);
                        return pillar.status === JsonStratPillarStatusEnum.Active ?
                            <OnePagerActionsContainer index={index} actions={actions} onAction={handleAction}>
                              <OnePagerEditableText index={index} value={ description } readonly={!isEditable}/>
                            </OnePagerActionsContainer> : <></>
                      })}
                    </OnePagerRow>

                    <OnePagerRow title={t('onePager.ns.' + ns + '.pillarTeam')} index={{ entity: OnepagerEntityType.PillarTeam }} actions={[CommonActionsType.Help]} onAction={handleAction} classx={styles.delta}>
                      { onePager.pillars?.map((pillar: JsonStratPillar, pillarIndex: number) => {
                        const index = { entity: OnepagerEntityType.PillarTeam, entityIndex: pillarIndex };
                        const actions = [];
                        if (isEditable) {
                          actions.push(CommonActionsType.Edit);
                        }
                        const description = parseArray(pillar?.description!, 1);
                        return pillar.status === JsonStratPillarStatusEnum.Active ?
                            <OnePagerActionsContainer index={index} actions={actions} onAction={handleAction}>
                              <OnePagerEditableText index={index} value={ description } readonly={!isEditable}/>
                            </OnePagerActionsContainer> : <></>
                      })}
                    </OnePagerRow>

                </>
            }

            { mode === OnePagerMode.Strategy &&
                <>

                  <OnePagerRow title={t('onePager.ns.' + ns + '.strategicAmbition')} index={{ entity: OnepagerEntityType.Ambition }} actions={[CommonActionsType.Help]} onAction={handleAction} sx={contextStyle} classx={styles.alfa}>
                    <OnePagerActionsContainer index={{ entity: OnepagerEntityType.Ambition }} actions={(isEditable && isRootOnepager) ? [CommonActionsType.Edit] : []} onAction={handleAction}>
                      <OnePagerEditableText index={{ entity: OnepagerEntityType.Ambition }} value={ !isRootOnepager ? rootOnePager.ambition : onePager.ambition } readonly={!isEditable || !isRootOnepager}/>
                    </OnePagerActionsContainer>
                  </OnePagerRow>

                  { !isRootOnepager &&
                    <OnePagerRow title={t('onePager.ns.' + ns + '.teamStrategicAmbition')} index={{ entity: OnepagerEntityType.TeamAmbition }} actions={[CommonActionsType.Help]} onAction={handleAction}>
                      <OnePagerActionsContainer index={{ entity: OnepagerEntityType.Ambition }} actions={isEditable ? [CommonActionsType.Edit] : []} onAction={handleAction}>
                        <OnePagerEditableText index={{ entity: OnepagerEntityType.Ambition }} value={ onePager.ambition } readonly={!isEditable}/>
                      </OnePagerActionsContainer>
                    </OnePagerRow>
                  }

                  <OnePagerRow index={{ entity: OnepagerEntityType.Pillar }}>
                    { onePager.pillars?.map((pillar: JsonStratPillar, pillarIndex: number) => {
                      const index = { entity: OnepagerEntityType.Pillar, entityIndex: pillarIndex };
                      const order = onePager.pillars?.reduce((acc: number, item: JsonStratPillar, orderIndex: number) => { return acc + ((item.status === JsonStratPillarStatusEnum.Active && orderIndex <= pillarIndex) ? 1 : 0) }, 0) || 1;
                      return pillar.status === JsonStratPillarStatusEnum.Active ?
                          <OnePagerActionsContainer index={index}>
                            <div className={stylesOnepager.pillarHeader}>{t('onePager.ns.' + ns + '.strategicPillar')} #{ order }
                              <span style={{ position: 'relative' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <HelpIcon style={{ cursor: 'pointer', paddingTop: '0px', position: 'absolute', top: '-3px', left: '5px' }} onClick={() => handleAction(CommonActionsType.Help, { entity: OnepagerEntityType.PillarHeader } )}/>
                              </span>
                            </div>
                          </OnePagerActionsContainer> : <></>;
                    })}
                  </OnePagerRow>

                  { !isRootOnepager && <OnePagerRow title={t('onePager.ns.' + ns + '.parentStrategicPriorities')} index={{ entity: OnepagerEntityType.PillarParent }} actions={[CommonActionsType.Help]} onAction={handleAction}>
                    { onePager.pillars?.map((pillar: JsonStratPillar, pillarIndex: number) =>
                        <ParentPillarInfo parentOnePager={parentOnePager} pillar={pillar} pillarIndex={pillarIndex} isEditable={isEditable} onAction={handleAction}/>
                    )}
                  </OnePagerRow> }

                  <OnePagerRow title={t('onePager.ns.' + ns + '.strategicPriorities')} index={{ entity: OnepagerEntityType.Pillar }} actions={isEditable ? [CommonActionsType.Create, CommonActionsType.Help] : [CommonActionsType.Help]} onAction={handleAction}>
                    { onePager.pillars?.map((pillar: JsonStratPillar, pillarIndex: number) => {
                      const index = { entity: OnepagerEntityType.Pillar, entityIndex: pillarIndex };
                      const actions = [CommonActionsType.Down];
                      if (isEditable) {
                        actions.push(CommonActionsType.Delete);
                        actions.push(CommonActionsType.Edit);
                      }
                      return pillar.status === JsonStratPillarStatusEnum.Active ?
                          <OnePagerActionsContainer index={index} actions={actions} onAction={handleAction}>
                            <OnePagerEditableText index={index} value={ pillar.description } readonly={!isEditable}/>
                          </OnePagerActionsContainer> : <></>
                    })}
                  </OnePagerRow>

                </>

            }

            <OnePagerRow title={t('onePager.ns.' + ns + '.successLooks')} index={{ entity: OnepagerEntityType.PillarSuccessLook }} actions={[CommonActionsType.Help]} onAction={handleAction}  classx={styles.delta}>
              { onePager.pillars?.map((pillar: JsonStratPillar, pillarIndex: number) => {
                const index = { parentEntity: OnepagerEntityType.Pillar, parentEntityIndex: pillarIndex, entity: OnepagerEntityType.PillarSuccessLook };
                let enableNewSuccessLook = !pillar.successLooks || pillar?.successLooks?.filter((look: JsonStratSuccessLook) => look.status === JsonStratSuccessLookStatusEnum.Active).length < MAX_SUCCESS_LOOKS;

                return pillar.status === JsonStratPillarStatusEnum.Active ?
                    <OnePagerActionsContainer index={index} actions={isEditable && enableNewSuccessLook ? [CommonActionsType.Create] : []} onAction={handleAction} alwaysVisible positionRelative>
                      <div className={stylesOnepager.initiativesContainer}>
                        { pillar.successLooks?.map((successLook: JsonStratSuccessLook, successLookIndex: number) => {
                          const subIndex = { parentEntity: OnepagerEntityType.Pillar, parentEntityIndex: pillarIndex, entity: OnepagerEntityType.PillarSuccessLook, entityIndex: successLookIndex };
                          return successLook.status === JsonStratSuccessLookStatusEnum.Active ?
                              <OnePagerActionsContainer index={subIndex} actions={isEditable ? [CommonActionsType.Delete, CommonActionsType.Edit] : []} onAction={handleAction}>
                                <OnePagerEditableText index={subIndex} value={successLook.description} readonly={!isEditable}/>
                              </OnePagerActionsContainer> : <></>;
                        })
                        }
                      </div>
                    </OnePagerActionsContainer>
                    : <></>;
              })}
            </OnePagerRow>

            <OnePagerRow title={t('onePager.ns.' + ns + '.keyInitiatives')} index={{ entity: OnepagerEntityType.Initiative }} actions={[CommonActionsType.Help]} onAction={handleAction} classx={styles.delta}>
              { onePager.pillars?.map((pillar: JsonStratPillar, pillarIndex: number) => {
                const index = { parentEntity: OnepagerEntityType.Pillar, parentEntityIndex: pillarIndex, entity: OnepagerEntityType.Initiative };
                let enableNewInitiative = !pillar.initiatives || pillar?.initiatives?.filter((initiative: JsonStratInitiative) => initiative.status === JsonStratInitiativeStatusEnum.Active).length < MAX_INITIATIVES;
                return pillar.status === JsonStratPillarStatusEnum.Active ?
                    <OnePagerActionsContainer index={index} actions={isEditable && enableNewInitiative ? [CommonActionsType.Create] : []} onAction={handleAction} alwaysVisible positionRelative>
                      <div className={stylesOnepager.initiativesContainer}>
                        { pillar.initiatives?.map((initiative: JsonStratInitiative, initiativeIndex: number) => {
                          const subIndex = { parentEntity: OnepagerEntityType.Pillar, parentEntityIndex: pillarIndex, entity: OnepagerEntityType.Initiative, entityIndex: initiativeIndex };
                          return initiative.status === JsonStratInitiativeStatusEnum.Active ?
                              <OnePagerActionsContainer index={subIndex} actions={isEditable ? [CommonActionsType.Delete, CommonActionsType.Edit] : []} onAction={handleAction}>
                                <OnePagerEditableText index={subIndex} value={initiative.description} readonly={!isEditable}/>
                              </OnePagerActionsContainer> : <></>;
                          })
                        }
                      </div>
                    </OnePagerActionsContainer> : <></>;
              })}
            </OnePagerRow>

            <OnePagerRow title={t('onePager.ns.' + ns + '.enablers')} index={{ entity: OnepagerEntityType.Enabler }} actions={isEditable && enableNewEnabler ? [CommonActionsType.Create, CommonActionsType.Help] : [CommonActionsType.Help]} onAction={handleAction} classx={styles.beta}>
              { onePager.enablers?.map((enabler: JsonStratEnabler, enablerIndex: number) => {
                const index = { entity: OnepagerEntityType.Enabler, entityIndex: enablerIndex };
                const actions = [];
                if (isEditable) {
                  actions.push(CommonActionsType.Delete);
                  actions.push(CommonActionsType.Edit);
                  if (mode === OnePagerMode.Strategy) {
                    actions.push(CommonActionsType.Down);
                  }
                }
                return enabler.status === JsonStratEnablerStatusEnum.Active ?
                    <OnePagerActionsContainer index={index} actions={actions} onAction={handleAction}>
                      <OnePagerEditableText index={index} value={ enabler.description } readonly={!isEditable}/>
                    </OnePagerActionsContainer> : <></>;
              })}
            </OnePagerRow>

            <OnePagerRow title={t('onePager.ns.' + ns + '.cvbs')} index={{ entity: OnepagerEntityType.CVB }} actions={(isEditable && isRootOnepager && enableNewCVBs) ? [CommonActionsType.Create, CommonActionsType.Help] : [CommonActionsType.Help] } onAction={handleAction} sx={contextStyle} classx={styles.alfa}>
              { (isRootOnepager ? onePager : rootOnePager).cvbs?.map((cvb: JsonStratCvb, cvbIndex: number) => {
                const index = { entity: OnepagerEntityType.CVB, entityIndex: cvbIndex };
                return (cvb.status === JsonStratCvbStatusEnum.Active || !isRootOnepager) ?
                    <OnePagerActionsContainer index={index} actions={(!isEditable || !isRootOnepager) ? [] : [CommonActionsType.Delete, CommonActionsType.Edit] } onAction={handleAction}>
                      <OnePagerEditableText index={index} value={ cvb.description } readonly={!isEditable || !isRootOnepager}/>
                    </OnePagerActionsContainer> : <></>;
              })}
            </OnePagerRow>

          </Grid>

          { !!showComments && <CommentsContainer onePager={onePager}></CommentsContainer> }

        </Grid>
        { /* isEditable  && */
            <OnePagerButtons onSave={handleSave} onePager={onePager} mode={mode}/>
        }
        <PillarStructureModal pivot={structurePivot} onClose={() => setStructurePivot(undefined)}/>
      </Container>
      }
        { /* <CommentsModal onePager={onePager} show={showComments} onClose={() => setShowComments(false)}></CommentsModal> */ }
      <HelpModal help={help} onClose={() => setHelp(null)}></HelpModal>
    </>
	);
}

export default OnePagerPage;
