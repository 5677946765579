/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonStratUser,
    ApiResultOfJsonStratUserFromJSON,
    ApiResultOfJsonStratUserToJSON,
    ApiResultOfJsonStratUserInfo,
    ApiResultOfJsonStratUserInfoFromJSON,
    ApiResultOfJsonStratUserInfoToJSON,
    ApiResultOfListOfJsonStratUser,
    ApiResultOfListOfJsonStratUserFromJSON,
    ApiResultOfListOfJsonStratUserToJSON,
    ApiResultOfListOfJsonStratUserInfo,
    ApiResultOfListOfJsonStratUserInfoFromJSON,
    ApiResultOfListOfJsonStratUserInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonStratUser,
    JsonStratUserFromJSON,
    JsonStratUserToJSON,
} from '../models';

export interface GetStratUserCountUsingGETRequest {
    companyId?: number;
    strategyId?: number;
    search?: string;
}

export interface GetStratUserInfoUsingGETRequest {
    stratUserId: number;
}

export interface GetStratUserListUsingGETRequest {
    companyId?: number;
    strategyId?: number;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetStratUserUsingGETRequest {
    stratUserId: number;
}

export interface PrepareStratUserImportUsingPOSTRequest {
    file: Blob;
    strategyId?: any | null;
    testOnly?: any | null;
}

export interface SaveStratUserUsingPOSTRequest {
    json: JsonStratUser;
    testOnly?: boolean;
}

/**
 * 
 */
export class StratUserControllerApi extends runtime.BaseAPI {

    /**
     * getStratUserCount
     */
    async getStratUserCountUsingGETRaw(requestParameters: GetStratUserCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.strategyId !== undefined) {
            queryParameters['strategyId'] = requestParameters.strategyId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/strat-user/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getStratUserCount
     */
    async getStratUserCountUsingGET(requestParameters: GetStratUserCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getStratUserCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getStratUserInfo
     */
    async getStratUserInfoUsingGETRaw(requestParameters: GetStratUserInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonStratUserInfo>> {
        if (requestParameters.stratUserId === null || requestParameters.stratUserId === undefined) {
            throw new runtime.RequiredError('stratUserId','Required parameter requestParameters.stratUserId was null or undefined when calling getStratUserInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/strat-user/info/{stratUserId}`.replace(`{${"stratUserId"}}`, encodeURIComponent(String(requestParameters.stratUserId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonStratUserInfoFromJSON(jsonValue));
    }

    /**
     * getStratUserInfo
     */
    async getStratUserInfoUsingGET(requestParameters: GetStratUserInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonStratUserInfo> {
        const response = await this.getStratUserInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getStratUserList
     */
    async getStratUserListUsingGETRaw(requestParameters: GetStratUserListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonStratUserInfo>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.strategyId !== undefined) {
            queryParameters['strategyId'] = requestParameters.strategyId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/strat-user/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonStratUserInfoFromJSON(jsonValue));
    }

    /**
     * getStratUserList
     */
    async getStratUserListUsingGET(requestParameters: GetStratUserListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonStratUserInfo> {
        const response = await this.getStratUserListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getStratUser
     */
    async getStratUserUsingGETRaw(requestParameters: GetStratUserUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonStratUser>> {
        if (requestParameters.stratUserId === null || requestParameters.stratUserId === undefined) {
            throw new runtime.RequiredError('stratUserId','Required parameter requestParameters.stratUserId was null or undefined when calling getStratUserUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/strat-user/{stratUserId}`.replace(`{${"stratUserId"}}`, encodeURIComponent(String(requestParameters.stratUserId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonStratUserFromJSON(jsonValue));
    }

    /**
     * getStratUser
     */
    async getStratUserUsingGET(requestParameters: GetStratUserUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonStratUser> {
        const response = await this.getStratUserUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * prepareStratUserImport
     */
    async prepareStratUserImportUsingPOSTRaw(requestParameters: PrepareStratUserImportUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonStratUser>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling prepareStratUserImportUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.strategyId !== undefined) {
            formParams.append('strategyId',requestParameters.strategyId);
                    }

        if (requestParameters.testOnly !== undefined) {
            formParams.append('testOnly', requestParameters.testOnly);
                    }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/rest-service/strat-user/import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonStratUserFromJSON(jsonValue));
    }

    /**
     * prepareStratUserImport
     */
    async prepareStratUserImportUsingPOST(requestParameters: PrepareStratUserImportUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonStratUser> {
        const response = await this.prepareStratUserImportUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveStratUser
     */
    async saveStratUserUsingPOSTRaw(requestParameters: SaveStratUserUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonStratUser>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveStratUserUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/strat-user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonStratUserToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonStratUserFromJSON(jsonValue));
    }

    /**
     * saveStratUser
     */
    async saveStratUserUsingPOST(requestParameters: SaveStratUserUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonStratUser> {
        const response = await this.saveStratUserUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
