/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonStratSuccessLook
 */
export interface JsonStratSuccessLook {
    /**
     * 
     * @type {string}
     * @memberof JsonStratSuccessLook
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonStratSuccessLook
     */
    createdBy?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonStratSuccessLook
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JsonStratSuccessLook
     */
    isRemoved?: boolean;
    /**
     * 
     * @type {number}
     * @memberof JsonStratSuccessLook
     */
    orderNo?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratSuccessLook
     */
    pillarId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonStratSuccessLook
     */
    status?: JsonStratSuccessLookStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof JsonStratSuccessLook
     */
    successLookId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonStratSuccessLook
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonStratSuccessLook
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonStratSuccessLookStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}

export function JsonStratSuccessLookFromJSON(json: any): JsonStratSuccessLook {
    return JsonStratSuccessLookFromJSONTyped(json, false);
}

export function JsonStratSuccessLookFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonStratSuccessLook {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'isRemoved': !exists(json, 'isRemoved') ? undefined : json['isRemoved'],
        'orderNo': !exists(json, 'orderNo') ? undefined : json['orderNo'],
        'pillarId': !exists(json, 'pillarId') ? undefined : json['pillarId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'successLookId': !exists(json, 'successLookId') ? undefined : json['successLookId'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonStratSuccessLookToJSON(value?: JsonStratSuccessLook | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'description': value.description,
        'isRemoved': value.isRemoved,
        'orderNo': value.orderNo,
        'pillarId': value.pillarId,
        'status': value.status,
        'successLookId': value.successLookId,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

