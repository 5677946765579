/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonStratOnepager,
    ApiResultOfJsonStratOnepagerFromJSON,
    ApiResultOfJsonStratOnepagerToJSON,
    ApiResultOfJsonStratOnepagerInfo,
    ApiResultOfJsonStratOnepagerInfoFromJSON,
    ApiResultOfJsonStratOnepagerInfoToJSON,
    ApiResultOfListOfJsonStratOnepagerInfo,
    ApiResultOfListOfJsonStratOnepagerInfoFromJSON,
    ApiResultOfListOfJsonStratOnepagerInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonStratOnepager,
    JsonStratOnepagerFromJSON,
    JsonStratOnepagerToJSON,
} from '../models';

export interface ExportStratOnepagerUsingGETRequest {
    onepagerId: number;
    lang?: ExportStratOnepagerUsingGETLangEnum;
}

export interface GetStratOnepagerCountUsingGETRequest {
    companyId?: number;
    strategyId?: number;
    positionId?: number;
    companyPositionId?: number;
    departmentId?: number;
    companyDepartmentId?: number;
    onepagerIds?: Array<number>;
    pivotUserId?: number;
    pivotOnepagerId?: number;
    pivotMode?: GetStratOnepagerCountUsingGETPivotModeEnum;
    statuses?: GetStratOnepagerCountUsingGETStatusesEnum;
    search?: string;
}

export interface GetStratOnepagerForSerieUsingGETRequest {
    serieId: number;
    userId?: number;
}

export interface GetStratOnepagerInfoUsingGETRequest {
    onepagerId: number;
}

export interface GetStratOnepagerListUsingGETRequest {
    companyId?: number;
    strategyId?: number;
    positionId?: number;
    companyPositionId?: number;
    departmentId?: number;
    companyDepartmentId?: number;
    onepagerIds?: Array<number>;
    pivotUserId?: number;
    pivotOnepagerId?: number;
    pivotMode?: GetStratOnepagerListUsingGETPivotModeEnum;
    statuses?: GetStratOnepagerListUsingGETStatusesEnum;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetStratOnepagerUsingGETRequest {
    onepagerId: number;
}

export interface SaveStratOnepagerUsingPOSTRequest {
    json: JsonStratOnepager;
    testOnly?: boolean;
}

/**
 * 
 */
export class StratOnepagerControllerApi extends runtime.BaseAPI {

    /**
     * exportStratOnepager
     */
    async exportStratOnepagerUsingGETRaw(requestParameters: ExportStratOnepagerUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.onepagerId === null || requestParameters.onepagerId === undefined) {
            throw new runtime.RequiredError('onepagerId','Required parameter requestParameters.onepagerId was null or undefined when calling exportStratOnepagerUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/strat-onepager/{onepagerId}/export/**`.replace(`{${"onepagerId"}}`, encodeURIComponent(String(requestParameters.onepagerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * exportStratOnepager
     */
    async exportStratOnepagerUsingGET(requestParameters: ExportStratOnepagerUsingGETRequest, initOverrides?: RequestInit): Promise<void> {
        await this.exportStratOnepagerUsingGETRaw(requestParameters, initOverrides);
    }

    /**
     * getStratOnepagerCount
     */
    async getStratOnepagerCountUsingGETRaw(requestParameters: GetStratOnepagerCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.strategyId !== undefined) {
            queryParameters['strategyId'] = requestParameters.strategyId;
        }

        if (requestParameters.positionId !== undefined) {
            queryParameters['positionId'] = requestParameters.positionId;
        }

        if (requestParameters.companyPositionId !== undefined) {
            queryParameters['companyPositionId'] = requestParameters.companyPositionId;
        }

        if (requestParameters.departmentId !== undefined) {
            queryParameters['departmentId'] = requestParameters.departmentId;
        }

        if (requestParameters.companyDepartmentId !== undefined) {
            queryParameters['companyDepartmentId'] = requestParameters.companyDepartmentId;
        }

        if (requestParameters.onepagerIds) {
            queryParameters['onepagerIds'] = requestParameters.onepagerIds;
        }

        if (requestParameters.pivotUserId !== undefined) {
            queryParameters['pivotUserId'] = requestParameters.pivotUserId;
        }

        if (requestParameters.pivotOnepagerId !== undefined) {
            queryParameters['pivotOnepagerId'] = requestParameters.pivotOnepagerId;
        }

        if (requestParameters.pivotMode !== undefined) {
            queryParameters['pivotMode'] = requestParameters.pivotMode;
        }

        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/strat-onepager/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getStratOnepagerCount
     */
    async getStratOnepagerCountUsingGET(requestParameters: GetStratOnepagerCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getStratOnepagerCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getStratOnepagerForSerie
     */
    async getStratOnepagerForSerieUsingGETRaw(requestParameters: GetStratOnepagerForSerieUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonStratOnepager>> {
        if (requestParameters.serieId === null || requestParameters.serieId === undefined) {
            throw new runtime.RequiredError('serieId','Required parameter requestParameters.serieId was null or undefined when calling getStratOnepagerForSerieUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/strat-onepager/serie/{serieId}`.replace(`{${"serieId"}}`, encodeURIComponent(String(requestParameters.serieId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonStratOnepagerFromJSON(jsonValue));
    }

    /**
     * getStratOnepagerForSerie
     */
    async getStratOnepagerForSerieUsingGET(requestParameters: GetStratOnepagerForSerieUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonStratOnepager> {
        const response = await this.getStratOnepagerForSerieUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getStratOnepagerInfo
     */
    async getStratOnepagerInfoUsingGETRaw(requestParameters: GetStratOnepagerInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonStratOnepagerInfo>> {
        if (requestParameters.onepagerId === null || requestParameters.onepagerId === undefined) {
            throw new runtime.RequiredError('onepagerId','Required parameter requestParameters.onepagerId was null or undefined when calling getStratOnepagerInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/strat-onepager/info/{onepagerId}`.replace(`{${"onepagerId"}}`, encodeURIComponent(String(requestParameters.onepagerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonStratOnepagerInfoFromJSON(jsonValue));
    }

    /**
     * getStratOnepagerInfo
     */
    async getStratOnepagerInfoUsingGET(requestParameters: GetStratOnepagerInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonStratOnepagerInfo> {
        const response = await this.getStratOnepagerInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getStratOnepagerList
     */
    async getStratOnepagerListUsingGETRaw(requestParameters: GetStratOnepagerListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonStratOnepagerInfo>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.strategyId !== undefined) {
            queryParameters['strategyId'] = requestParameters.strategyId;
        }

        if (requestParameters.positionId !== undefined) {
            queryParameters['positionId'] = requestParameters.positionId;
        }

        if (requestParameters.companyPositionId !== undefined) {
            queryParameters['companyPositionId'] = requestParameters.companyPositionId;
        }

        if (requestParameters.departmentId !== undefined) {
            queryParameters['departmentId'] = requestParameters.departmentId;
        }

        if (requestParameters.companyDepartmentId !== undefined) {
            queryParameters['companyDepartmentId'] = requestParameters.companyDepartmentId;
        }

        if (requestParameters.onepagerIds) {
            queryParameters['onepagerIds'] = requestParameters.onepagerIds;
        }

        if (requestParameters.pivotUserId !== undefined) {
            queryParameters['pivotUserId'] = requestParameters.pivotUserId;
        }

        if (requestParameters.pivotOnepagerId !== undefined) {
            queryParameters['pivotOnepagerId'] = requestParameters.pivotOnepagerId;
        }

        if (requestParameters.pivotMode !== undefined) {
            queryParameters['pivotMode'] = requestParameters.pivotMode;
        }

        if (requestParameters.statuses) {
            queryParameters['statuses'] = requestParameters.statuses;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/strat-onepager/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonStratOnepagerInfoFromJSON(jsonValue));
    }

    /**
     * getStratOnepagerList
     */
    async getStratOnepagerListUsingGET(requestParameters: GetStratOnepagerListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonStratOnepagerInfo> {
        const response = await this.getStratOnepagerListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getStratOnepager
     */
    async getStratOnepagerUsingGETRaw(requestParameters: GetStratOnepagerUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonStratOnepager>> {
        if (requestParameters.onepagerId === null || requestParameters.onepagerId === undefined) {
            throw new runtime.RequiredError('onepagerId','Required parameter requestParameters.onepagerId was null or undefined when calling getStratOnepagerUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/strat-onepager/{onepagerId}`.replace(`{${"onepagerId"}}`, encodeURIComponent(String(requestParameters.onepagerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonStratOnepagerFromJSON(jsonValue));
    }

    /**
     * getStratOnepager
     */
    async getStratOnepagerUsingGET(requestParameters: GetStratOnepagerUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonStratOnepager> {
        const response = await this.getStratOnepagerUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveStratOnepager
     */
    async saveStratOnepagerUsingPOSTRaw(requestParameters: SaveStratOnepagerUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonStratOnepager>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveStratOnepagerUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/strat-onepager`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonStratOnepagerToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonStratOnepagerFromJSON(jsonValue));
    }

    /**
     * saveStratOnepager
     */
    async saveStratOnepagerUsingPOST(requestParameters: SaveStratOnepagerUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonStratOnepager> {
        const response = await this.saveStratOnepagerUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ExportStratOnepagerUsingGETLangEnum {
    Cs = 'cs',
    En = 'en'
}
/**
    * @export
    * @enum {string}
    */
export enum GetStratOnepagerCountUsingGETPivotModeEnum {
    Dashboard = 'DASHBOARD',
    DrillDown = 'DRILL_DOWN',
    SameLevel = 'SAME_LEVEL'
}
/**
    * @export
    * @enum {string}
    */
export enum GetStratOnepagerCountUsingGETStatusesEnum {
    New = 'NEW',
    Draft = 'DRAFT',
    Public = 'PUBLIC',
    Finished = 'FINISHED'
}
/**
    * @export
    * @enum {string}
    */
export enum GetStratOnepagerListUsingGETPivotModeEnum {
    Dashboard = 'DASHBOARD',
    DrillDown = 'DRILL_DOWN',
    SameLevel = 'SAME_LEVEL'
}
/**
    * @export
    * @enum {string}
    */
export enum GetStratOnepagerListUsingGETStatusesEnum {
    New = 'NEW',
    Draft = 'DRAFT',
    Public = 'PUBLIC',
    Finished = 'FINISHED'
}
