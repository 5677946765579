import * as React from 'react';
import {useAppTranslation} from "../../../services/i18n";
import {JsonStratOnepagerInfoStatusEnum, JsonStratOnepagerStatusEnum} from "../../../generated-api";

interface Props {
    status?: JsonStratOnepagerStatusEnum | JsonStratOnepagerInfoStatusEnum;
}

const StratairuOnePagerStatusFlag = ({ status }: Props) => {
	
    const t = useAppTranslation();

    let fill = '#000';
    let text = 'var(--strat-white)';
    switch (status) {
        case JsonStratOnepagerStatusEnum.New:
        case JsonStratOnepagerInfoStatusEnum.New:
            fill = 'var(--strat-cyan)';
            break;
        case JsonStratOnepagerStatusEnum.Draft:
        case JsonStratOnepagerInfoStatusEnum.Draft:
            fill = 'var(--strat-yellow)';
            text = 'var(--strat-blue)';
            break;
        case JsonStratOnepagerStatusEnum.Public:
        case JsonStratOnepagerInfoStatusEnum.Public:
            fill = 'var(--strat-violet)';
            break;
        case JsonStratOnepagerStatusEnum.Finished:
        case JsonStratOnepagerInfoStatusEnum.Finished:
            fill = 'var(--strat-blue)';
            break;
    }

	return (
        <svg height="40" xmlns="http://www.w3.org/2000/svg" fill={fill} stroke={fill} strokeWidth="10" strokeLinejoin="round" viewBox="-10 -5 205 60">
            <path d="M 0 25 L 25 0 190 0 L 190 50 L 25 50 Z"/>
            { status && <text x="102" y="32" font-family="Arial" font-size="20" strokeWidth="0" fill={text} stroke={text} textAnchor="middle">{ t('enums.opStatus.' + status) }</text> }
        </svg>
	);
}

export default StratairuOnePagerStatusFlag;
