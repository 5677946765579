import React, {PropsWithChildren, useCallback, useMemo} from 'react';
import {Container, Grid} from '@mui/material';
import styles from '../../../assets/styles/stratairu-onepager.module.css';
import {OnepagerEntityIndex, OnepagerEntityType} from "../../../model/onepager";
import {CommonActionsType} from "../../../model/form";

import StratairuOnePagerActionsContainer from "./StratairuOnePagerActionsContainer";
import StratairuOnePagerEditableText from "./StratairuOnePagerEditableText";
import {
    JsonStratCvb,
    JsonStratCvbStatusEnum,
    JsonStratEnabler,
    JsonStratEnablerStatusEnum,
    JsonStratInitiative,
    JsonStratInitiativeStatusEnum,
    JsonStratOnepagerInfo,
    JsonStratPillar,
    JsonStratPillarInfo,
    JsonStratPillarInfoStatusEnum,
    JsonStratSuccessLook,
    JsonStratSuccessLookStatusEnum
} from "../../../generated-api";
import {OnePagerHeaderTitleColor, OnePagerRowConfig, OnePagerRowSource} from "../../../helpers/onepagerContent";
import {useAppTranslation} from "../../../services/i18n";
import classnames from "classnames";
import {MAX_INITIATIVES, MAX_SUCCESS_LOOKS} from "../../../pages/stratairu/StratairuOnePagerPage";
import StratairuOnePagerParentPillarInfo from "./StratairuOnePagerParentPillarInfo";

interface Props extends OnePagerRowConfig {
    selfOnepager: JsonStratOnepagerInfo;
    parentOnepager: JsonStratOnepagerInfo;
    rootOnepager: JsonStratOnepagerInfo;
    onAction?: (action: CommonActionsType, index: OnepagerEntityIndex, source: OnePagerRowSource) => void;
}

const StratairuOnePagerComplexRow = ({ source, title, titleColor, entity, rowActions, actions, readonly, children, onAction, selfOnepager, parentOnepager, rootOnepager }: PropsWithChildren<Props>) => {

    const t = useAppTranslation();
    const index= useMemo(() => ({ entity }), [entity]);

    const handleAction = useCallback((action: CommonActionsType, index: OnepagerEntityIndex) => {
        if (onAction) {
            onAction(action, index, source);
        }
    }, [onAction, source])

    let sourceOnePager: JsonStratOnepagerInfo | undefined = undefined;
    switch (source) {
        case OnePagerRowSource.PARENT:
            sourceOnePager = parentOnepager;
            break;
        case OnePagerRowSource.SELF:
            sourceOnePager = selfOnepager;
            break;
        case OnePagerRowSource.ROOT:
            sourceOnePager = rootOnepager;
            break;
    }

    const addBottomMargin = [OnepagerEntityType.Purpose, OnepagerEntityType.Ambition, OnepagerEntityType.Enabler, OnepagerEntityType.CVB].includes(entity);
    const editableDraft = titleColor === OnePagerHeaderTitleColor.DARK;

    return (
      <Container>
        <Grid container style={{ marginTop: addBottomMargin ? '10px' : undefined }}>
            <Grid item md={4} xs={12}
                  className={classnames(title && titleColor ? styles.rowHeader : styles.noRowHeader, title ? (titleColor === OnePagerHeaderTitleColor.DARK ? styles.dark : styles.light) : undefined) }
                  style={{ margin: !addBottomMargin ? '5px 0px' : undefined }}
                >
                <StratairuOnePagerActionsContainer index={index} actions={rowActions} onAction={handleAction} alwaysVisible>
                    { title && <div className={styles.title}>
                        { t(title!) }
                        { /* hasTooltip && <span style={{ position: 'relative' }}>
                            <HelpIcon style={{ cursor: 'pointer', paddingTop: '0px', position: 'absolute', top: '-3px', left: '5px' }} onClick={() => handleRowAction(CommonActionsType.Help)}/>
                        </span> */ }
                    </div> }
                </StratairuOnePagerActionsContainer>
            </Grid>
          <Grid item md={8} xs={12} className={styles.rowContent} style={{ paddingLeft: '10px' }}>

                  <>
                  { (() => {
                      if ([OnepagerEntityType.Purpose, OnepagerEntityType.Ambition].includes(index.entity)) {
                        let value = undefined;
                        switch (index.entity) {
                            case OnepagerEntityType.Purpose:
                                value = sourceOnePager!.purpose;
                                break;
                            case OnepagerEntityType.Ambition:
                                value = sourceOnePager!.ambition;
                                break;
                        }
                        return (
                            <StratairuOnePagerActionsContainer index={ index } actions={actions} onAction={handleAction}>
                                <StratairuOnePagerEditableText index={ index } value={ value } readonly={ readonly } editableDraft={editableDraft}/>
                            </StratairuOnePagerActionsContainer>
                        );
                      }
                      if (index.entity === OnepagerEntityType.Enabler) {
                          return <div style={{ width: '100%' }}>{ sourceOnePager.enablers?.map((enabler: JsonStratEnabler, enablerIndex: number) => {
                              const index = { entity: OnepagerEntityType.Enabler, entityIndex: enablerIndex };
                              const actions = [];
                              if (!readonly) {
                                  actions.push(CommonActionsType.Delete);
                                  actions.push(CommonActionsType.Edit);
                                  actions.push(CommonActionsType.Down);
                              }
                              return enabler.status === JsonStratEnablerStatusEnum.Active ?
                                  <div style={{ width: '100%', marginBottom: '5px' }}>
                                  <StratairuOnePagerActionsContainer index={index} actions={actions} onAction={handleAction}>
                                      <StratairuOnePagerEditableText index={index} value={ enabler.description } readonly={readonly} editableDraft={editableDraft}/>
                                  </StratairuOnePagerActionsContainer></div> : <></>;
                          })}</div>
                      }
                      if (index.entity === OnepagerEntityType.CVB) {
                          console.log('CVBS', { sourceOnePager });
                          return <div style={{ display: 'flex', gap: '10px', width: '100%'}}>{ sourceOnePager.cvbs?.map((cvb: JsonStratCvb, cvbIndex: number) => {
                              const index = { entity: OnepagerEntityType.CVB, entityIndex: cvbIndex };
                              return (cvb.status === JsonStratCvbStatusEnum.Active || (source === OnePagerRowSource.ROOT && !cvb.status)) ?
                                  <div style={{ flex: 1 }}>
                                  <StratairuOnePagerActionsContainer index={index} actions={readonly ? [] : [CommonActionsType.Delete, CommonActionsType.Edit] } onAction={handleAction}>
                                      <StratairuOnePagerEditableText index={index} value={ cvb.description } readonly={readonly} sx={{ height: '100%' }} editableDraft={editableDraft}/>
                                  </StratairuOnePagerActionsContainer>
                                  </div> : <></>;
                          }) } </div>
                      }
                      if ([OnepagerEntityType.PillarHeader, OnepagerEntityType.PillarParent, OnepagerEntityType.Pillar, OnepagerEntityType.PillarSuccessLook, OnepagerEntityType.Initiative].includes(index.entity)) {
                          let borderRadius: string | undefined = undefined;
                          if (index.entity === OnepagerEntityType.Initiative) {
                              borderRadius = '0px 0px 15px 15px';
                          }
                          if (index.entity === OnepagerEntityType.PillarHeader) {
                              borderRadius = '15px 15px 0px 0px';
                          }
                          return <div className={styles.pillarContainer} style={{ marginTop: index.entity === OnepagerEntityType.PillarHeader ? '10px' : undefined }}>
                              { sourceOnePager?.pillars?.filter((pillar: JsonStratPillarInfo) => pillar.status === JsonStratPillarInfoStatusEnum.Active)
                                  .map((pillar: JsonStratPillarInfo, order: number) => {
                                    let content = <></>;
                                    let tempIndex: OnepagerEntityIndex = index;
                                    let tempActions = actions;
                                    if (index.entity === OnepagerEntityType.PillarHeader) {
                                        content = <div className={styles.pillarHeader}>{ t(title!, { order: order + 1 }) } </div>;
                                    }
                                    if (index.entity === OnepagerEntityType.Pillar) {
                                        const pillarIndex = sourceOnePager?.pillars?.findIndex((p: JsonStratPillarInfo) => p.pillarId === pillar.pillarId);
                                        tempIndex = { entity: OnepagerEntityType.Pillar, entityIndex: pillarIndex };
                                        tempActions = [];
                                        if (!readonly) {
                                            tempActions.push(CommonActionsType.Delete);
                                            tempActions.push(CommonActionsType.Edit);
                                        }
                                        tempActions.push(CommonActionsType.Down)
                                        content = <StratairuOnePagerEditableText index={tempIndex} value={ pillar.description } readonly={readonly} sx={{ height: '100%' }} editableDraft={editableDraft}/>;
                                    }
                                    if (index.entity === OnepagerEntityType.PillarSuccessLook) {
                                        const pillarIndex = sourceOnePager?.pillars?.findIndex((p: JsonStratPillarInfo) => p.pillarId === pillar.pillarId);
                                        tempIndex = { parentEntity: OnepagerEntityType.Pillar, parentEntityIndex: pillarIndex, entity: OnepagerEntityType.PillarSuccessLook };
                                        const enableNewSuccessLook = !pillar.successLooks || pillar?.successLooks?.filter((look: JsonStratSuccessLook) => look.status === JsonStratSuccessLookStatusEnum.Active).length < MAX_SUCCESS_LOOKS;
                                        tempActions = enableNewSuccessLook && !readonly ? [CommonActionsType.Create] : [];

                                        content = <div className={styles.pillarMultiple}>{ pillar.successLooks?.map((successLook: JsonStratSuccessLook, successLookIndex: number) => {
                                            const subIndex = { parentEntity: OnepagerEntityType.Pillar, parentEntityIndex: pillarIndex, entity: OnepagerEntityType.PillarSuccessLook, entityIndex: successLookIndex };
                                            return successLook.status === JsonStratSuccessLookStatusEnum.Active ?
                                                <StratairuOnePagerActionsContainer index={subIndex} actions={!readonly ? [CommonActionsType.Delete, CommonActionsType.Edit] : []} onAction={handleAction}>
                                                    <StratairuOnePagerEditableText index={subIndex} value={successLook.description} readonly={readonly} editableDraft={editableDraft}/>
                                                </StratairuOnePagerActionsContainer> : <></>;
                                        }) }</div>
                                    }

                                      if (index.entity === OnepagerEntityType.Initiative) {
                                          const pillarIndex = sourceOnePager?.pillars?.findIndex((p: JsonStratPillarInfo) => p.pillarId === pillar.pillarId);
                                          tempIndex = { parentEntity: OnepagerEntityType.Pillar, parentEntityIndex: pillarIndex, entity: OnepagerEntityType.Initiative };
                                          const enableNewInitiative = !pillar.initiatives || pillar?.initiatives?.filter((initiative: JsonStratInitiative) => initiative.status === JsonStratInitiativeStatusEnum.Active).length < MAX_INITIATIVES;
                                          tempActions = enableNewInitiative && !readonly ? [CommonActionsType.Create] : [];

                                          content = <div className={styles.pillarMultiple}>{ pillar.initiatives?.map((initiative: JsonStratInitiative, initiativeIndex: number) => {
                                              const subIndex = { parentEntity: OnepagerEntityType.Pillar, parentEntityIndex: pillarIndex, entity: OnepagerEntityType.Initiative, entityIndex: initiativeIndex };
                                              return initiative.status === JsonStratInitiativeStatusEnum.Active ?
                                                  <StratairuOnePagerActionsContainer index={subIndex} actions={!readonly ? [CommonActionsType.Delete, CommonActionsType.Edit] : []} onAction={handleAction}>
                                                      <StratairuOnePagerEditableText index={subIndex} value={initiative.description} readonly={readonly} editableDraft={editableDraft}/>
                                                  </StratairuOnePagerActionsContainer> : <></>;
                                          })
                                          }</div>
                                      }

                                      if (index.entity === OnepagerEntityType.PillarParent) {
                                          const pillarIndex = sourceOnePager?.pillars?.findIndex((p: JsonStratPillarInfo) => p.pillarId === pillar.pillarId);
                                          content = <div>
                                              <StratairuOnePagerParentPillarInfo parentOnePager={parentOnepager} pillar={pillar as JsonStratPillar} pillarIndex={pillarIndex} isEditable={!readonly} onAction={handleAction}/>
                                          </div>
                                      }

                                    return <StratairuOnePagerActionsContainer index={tempIndex} actions={tempActions} onAction={handleAction}>
                                        <div className={styles.pillarItem}
                                             style={{
                                                 marginBottom: tempActions?.includes(CommonActionsType.Create) ? '50px' : undefined,
                                                 borderRadius
                                            }}
                                        >{ content }</div>
                                    </StratairuOnePagerActionsContainer>
                                  })
                              }
                          </div>
                      }
                  })() }
                  </>

          </Grid>
        </Grid>
      </Container>
	);
}

export default StratairuOnePagerComplexRow;
