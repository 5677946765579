import React, {PropsWithChildren, useCallback, useMemo, useState} from 'react';
import styles from '../../../assets/styles/stratairu-onepager.module.css';
import {OnepagerEntityIndex} from "../../../model/onepager";
import {CircleButton} from "../../../components/layout/CircleButton";
import {alwaysVisibleActions, CommonActionsType} from "../../../model/form";
import {useSelector} from "react-redux";
import {selectStrategyOnePagerActiveIndex} from "../../../store/selectors";
import {StratairuCircleButton} from "./StratairuCircleButton";

interface Props {
    index: OnepagerEntityIndex;
    actions?: CommonActionsType[];
    alwaysVisible?: boolean;
    positionRelative?: boolean;
    onAction?: (action: CommonActionsType, index: OnepagerEntityIndex) => void;
}

const StratairuOnePagerActionsContainer = ({ index, actions, alwaysVisible, positionRelative, onAction, children }: PropsWithChildren<Props>) => {

    const [isButtonVisible, setIsButtonVisible] = useState<boolean>(false);

    const activeIndex = useSelector(selectStrategyOnePagerActiveIndex);

    const isActive = useMemo(() => {
        return (JSON.stringify(activeIndex) === JSON.stringify(index));
    }, [activeIndex, index]);

    const handleAction = useCallback((action: CommonActionsType) => {
        if (onAction) {
            onAction(action, index);
        }
    }, [index, onAction])

    return <div onMouseOver={() => setIsButtonVisible(true)} onMouseOut={() => setIsButtonVisible(false)} className={styles.entityContainer}>
              { children }
              { !!actions?.length &&
                  <div className={positionRelative ? styles.entityContainerButtonsRelative : styles.entityContainerButtons}>
                      { actions?.map((action) => ((isButtonVisible && !isActive) || alwaysVisible || alwaysVisibleActions.includes(action)) ?
                          <StratairuCircleButton key={action} action={action} onClick={() => handleAction(action)} /> : <></>
                      ) }
                  </div>
              }
          </div>
	;
}

export default StratairuOnePagerActionsContainer;
