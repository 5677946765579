import {useParams} from "react-router-dom";
import {Container, Grid} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {useAppDispatch} from '../../store';
import {
  GetStratPillarListUsingGETPivotModeEnum,
  JsonPoplistItem,
  JsonStratOnepagerInfo,
  JsonStratOnepagerStatusEnum
} from '../../generated-api';
import {getLocale, useAppTranslation} from '../../services/i18n';
import {
  addOnepagerEntity,
  deleteOnepagerEntity,
  fetchComments,
  fetchDashboard,
  fetchOnePager,
  fetchStrategyContext,
  saveOnePager,
  setOnepagerActiveIndex
} from "../../store/strategy";
import {useSelector} from "react-redux";
import {
  selectAuthUser,
  selectCodebooks,
  selectStrategyContext,
  selectStrategyOnePager,
  selectStrategyOnePagerActiveIndex,
  selectStrategyParentOnePager,
  selectStrategyRootOnePager,
  selectStrategySelfOnePager,
} from "../../store/selectors";
import {addMessage} from "../../store/localApp";
import {OnepagerEntityIndex, OnepagerEntityType} from "../../model/onepager";
import {CommonActionsType} from "../../model/form";
import HelpModal from "../../components/layout/HelpModal";
import {useInterval} from "../../hooks/useInterval";
import {useModal} from "../../services/modal";
import {addVisited} from "../../store/auth";

import StratairuPageHeader from "../../components/stratairu/components/StratairuPageHeader";
import StratairuLabel from "../../components/stratairu/components/StratairuLabel";
import StratairuOnePagerButtons from "../../components/stratairu/components/StratairuOnePagerButtons";
import StratairuOnePagerPillarStructureModal, {
  PillarStructurePivot
} from "../../components/stratairu/components/StratairuOnePagerPillarStructureModal";
import StratairuOnePagerCommentsContainer
  from "../../components/stratairu/components/StratairuOnePagerCommentsContainer";
import {getOnePagerContentDef, getOnePagerNamespace, OnePagerRowSource} from "../../helpers/onepagerContent";
import StratairuOnePagerComplexRow from "../../components/stratairu/components/StratairuOnePagerComplexRow";
import StratairuPage from "../../components/stratairu/components/StratairuPage";
import {useOnePager} from "../../hooks/useOnePager";
import StratairuCommentIcon from "../../components/stratairu/components/StratairuCommentIcon";
import StratairuExportIcon from "../../components/stratairu/components/StratairuExportIcon";
import {apiBasePath} from "../../app/config";
import StratairuOnePagerStatusProgress from "../../components/stratairu/components/StratairuOnePagerStatusProgress";


export const MAX_PILLARS = 5;
export const MAX_ENABLERS = 5;
export const MAX_CVBS = 5;
export const MAX_INITIATIVES = 5;
export const MAX_SUCCESS_LOOKS = 5;

const StratairuOnePagerPage = () => {

  const t = useAppTranslation();

  const dispatch = useAppDispatch();
  const modal = useModal();

  const onePager = useSelector(selectStrategyOnePager);
  const selfOnePager = useSelector(selectStrategySelfOnePager);
  const rootOnePager = useSelector(selectStrategyRootOnePager);
  const parentOnePager = useSelector(selectStrategyParentOnePager);

  const context = useSelector(selectStrategyContext);
  const activeIndex = useSelector(selectStrategyOnePagerActiveIndex);
  const codebooks = useSelector(selectCodebooks);

  const user = useSelector(selectAuthUser);

  const [structurePivot, setStructurePivot] = useState<PillarStructurePivot|undefined>(undefined);
  const [showComments, setShowComments] = useState<boolean>(false);
  const [help, setHelp] = useState<string | null>(null);
  const [initialized, setInitialized] = useState<string|undefined>(undefined);
  const [accessGranted, setAccessGranted] = useState<boolean>(false);

  const { id } = useParams();
  const { checkAccess } = useOnePager();

  const isRootOnepager = onePager?.onepagerId === rootOnePager?.onepagerId;

  const loggedUser = useSelector(selectAuthUser);

  const ns = getOnePagerNamespace(loggedUser, onePager, selfOnePager, parentOnePager, rootOnePager);

  useEffect(() => {

    if (!loggedUser?.userId) {
      return;
    }

    if (id && initialized !== id) {
      setInitialized(id);
      dispatch(fetchOnePager(+id))
          .then((result: any) => {
            if (result?.payload?.data) {
              if (checkAccess(result?.payload?.data as JsonStratOnepagerInfo)) {
                const strategyId = +result.payload.data.strategyId;
                dispatch(fetchStrategyContext(strategyId));
                dispatch(fetchDashboard({ strategyId: strategyId }));
                dispatch(fetchComments(+id));
                dispatch(addVisited(+id));
                setAccessGranted(true);
              } else {
                setAccessGranted(false);
              }
            }
          });
    }
  }, [dispatch, id, loggedUser, initialized, checkAccess]);

  const onSave = useCallback(async(status: JsonStratOnepagerStatusEnum | undefined, showMessage: boolean = true) => {
    if (onePager) {
      const saveResult = await dispatch(saveOnePager(status));
      if (showMessage) {
        if (saveResult) {
          dispatch(addMessage({
            severity: 'success',
            title: t('stratairu.messages.onePagerSaveSuccess'),
          }));
        } else {
          dispatch(addMessage({
            severity: 'error',
            title: t('stratairu.messages.onePagerSaveError'),
          }));
        }
      }
    }
  }, [onePager, dispatch, t])

  const handleAction = useCallback(async (action: CommonActionsType, index: OnepagerEntityIndex, source: OnePagerRowSource) => {
    console.log({ action, index });
    switch (action) {
      case CommonActionsType.Create:
        await dispatch(addOnepagerEntity(index));
        if (index.entity === OnepagerEntityType.Pillar) {
          await onSave(undefined, false);
        }
        break;
      case CommonActionsType.Delete:
        const confirmResult = await modal.confirm({ title: t('onePager.messages.confirmation'), message: t('onePager.messages.deleteConfirm.' + index.entity) });
        if (confirmResult !== 'CONFIRM') {
          return;
        }
        dispatch(deleteOnepagerEntity(index));
        break;
      case CommonActionsType.Edit:
        dispatch(setOnepagerActiveIndex(index));
        break;
      case CommonActionsType.Down:
        if (index.entity === OnepagerEntityType.Pillar) {
          const pillar = onePager?.pillars[index.entityIndex!];
          setStructurePivot({
            pivotPillarId: pillar.pillarId,
            pivotMode: GetStratPillarListUsingGETPivotModeEnum.Down
          });
        }
        if (index.entity === OnepagerEntityType.Enabler) {
          const enabler = onePager?.enablers[index.entityIndex!];
          setStructurePivot({
            pivotEnablerId: enabler.enablerId,
            pivotMode: GetStratPillarListUsingGETPivotModeEnum.Down
          })
        }
        break;
      case CommonActionsType.Up:
        if (index.entity === OnepagerEntityType.Pillar) {
          const pillar = onePager?.pillars[index.entityIndex!];
          setStructurePivot({
            pivotPillarId: pillar.pillarId,
            pivotMode: GetStratPillarListUsingGETPivotModeEnum.Up
          });
        }
        break;
      case CommonActionsType.Help:
        let postfix = '';
        if (source !== OnePagerRowSource.SELF && [OnepagerEntityType.Ambition, OnepagerEntityType.Pillar].includes(index.entity)) {
          postfix = 'Parent';
        }
        setHelp('stratairu.onePager.' + ns + '.help.' + index.entity + postfix);
        break;
    }
  }, [onePager, dispatch, ns, modal, onSave, t]);

  const handleSave = useCallback(async (status: JsonStratOnepagerStatusEnum, showMessage: boolean = true) => {
    // check open text area
    if (activeIndex) {
      await dispatch(setOnepagerActiveIndex(undefined));
      setTimeout(async () => {
        await onSave(status, showMessage);
      }, 0);
    } else {
      await onSave(status, showMessage);
    }
  }, [activeIndex, onSave, dispatch]);

  const handleExportPDF = useCallback(() => {
    window.open(`${apiBasePath(getLocale())}/rest-service/strat-onepager/${onePager?.onepagerId}/export/onepager.pdf`, '_blank');
  }, [onePager])

  // autosaver
  useInterval(() => {
    onSave(undefined, false);
  }, 60000);

  const content = getOnePagerContentDef(user, onePager, selfOnePager, parentOnePager, rootOnePager);

  const departmentId = selfOnePager?.companyDepartmentId || selfOnePager?.departmentId;
  const departmentName = departmentId ? (selfOnePager.companyDepartmentId ? context?.companyDepartments : codebooks['companyDepartment'])
      .find((item: JsonPoplistItem) => item.value === departmentId || item.value === departmentId + '' )?.label : '';

  return (
    <>
		{ !!onePager && !!rootOnePager && !!accessGranted &&
            <StratairuPage>
              <Container>
                <Grid container>
                  <Grid item xs={12}>
                    <StratairuPageHeader>
                    </StratairuPageHeader>
                  </Grid>
                  <Grid item xs={12}>
                    <StratairuLabel content={<div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                      <div>
                          { user.userId === onePager.userId ? t('stratairu.titles.myOnePager') : <span>{ selfOnePager.firstName + ' ' + selfOnePager.lastName } <span style={{ fontWeight: 200 }}>, { departmentName }</span></span> }
                      </div>
                      <StratairuOnePagerStatusProgress status={onePager.status}/>
                    </div>}>
                      <div style={{ display: 'flex', gap: '10px' }}>
                        <StratairuExportIcon onClick={handleExportPDF} />
                        <StratairuCommentIcon onClick={() => setShowComments((show) => !show)}/>
                      </div>
                    </StratairuLabel>
                  </Grid>
                  <Grid item xs={12} md={showComments ? 9 : 12}>
                    { content?.map((row) =>
                        <StratairuOnePagerComplexRow
                            { ...row }
                            onAction={handleAction}
                            selfOnepager={onePager}
                            parentOnepager={parentOnePager}
                            rootOnepager={rootOnePager}
                        ></StratairuOnePagerComplexRow>
                    )}
                  </Grid>

                  { !!showComments && <StratairuOnePagerCommentsContainer onePager={onePager}></StratairuOnePagerCommentsContainer> }

                </Grid>
                { /* isEditable  && */
                    <StratairuOnePagerButtons onSave={handleSave} onePager={onePager} isRootOnepager={isRootOnepager}/>
                }
                <StratairuOnePagerPillarStructureModal pivot={structurePivot} onClose={() => setStructurePivot(undefined)}/>
              </Container>
              </StratairuPage>
      }
        { /* <CommentsModal onePager={onePager} show={showComments} onClose={() => setShowComments(false)}></CommentsModal> */ }
      <HelpModal help={help} onClose={() => setHelp(null)}></HelpModal>
    </>
	);
}

export default StratairuOnePagerPage;
