/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';
import {
    JsonErrorInfo,
    JsonErrorInfoFromJSON,
    JsonErrorInfoFromJSONTyped,
    JsonErrorInfoToJSON,
} from './JsonErrorInfo';

/**
 * 
 * @export
 * @interface JsonStratUser
 */
export interface JsonStratUser {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonStratUser
     */
    allowedActions?: Array<JsonStratUserAllowedActionsEnum>;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonStratUser
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {number}
     * @memberof JsonStratUser
     */
    companyDepartmentId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratUser
     */
    companyPositionId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonStratUser
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonStratUser
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratUser
     */
    departmentId?: number;
    /**
     * 
     * @type {Date}
     * @memberof JsonStratUser
     */
    dueDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof JsonStratUser
     */
    notifFlag?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratUser
     */
    parentUserId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratUser
     */
    positionId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratUser
     */
    roleId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratUser
     */
    rowNo?: number;
    /**
     * 
     * @type {Array<JsonErrorInfo>}
     * @memberof JsonStratUser
     */
    saveErrors?: Array<JsonErrorInfo>;
    /**
     * 
     * @type {number}
     * @memberof JsonStratUser
     */
    stratUserId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratUser
     */
    strategyId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonStratUser
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonStratUser
     */
    updatedBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratUser
     */
    userId?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonStratUserAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}

export function JsonStratUserFromJSON(json: any): JsonStratUser {
    return JsonStratUserFromJSONTyped(json, false);
}

export function JsonStratUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonStratUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'companyDepartmentId': !exists(json, 'companyDepartmentId') ? undefined : json['companyDepartmentId'],
        'companyPositionId': !exists(json, 'companyPositionId') ? undefined : json['companyPositionId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'departmentId': !exists(json, 'departmentId') ? undefined : json['departmentId'],
        'dueDate': !exists(json, 'dueDate') ? undefined : (new Date(json['dueDate'])),
        'notifFlag': !exists(json, 'notifFlag') ? undefined : json['notifFlag'],
        'parentUserId': !exists(json, 'parentUserId') ? undefined : json['parentUserId'],
        'positionId': !exists(json, 'positionId') ? undefined : json['positionId'],
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'rowNo': !exists(json, 'rowNo') ? undefined : json['rowNo'],
        'saveErrors': !exists(json, 'saveErrors') ? undefined : ((json['saveErrors'] as Array<any>).map(JsonErrorInfoFromJSON)),
        'stratUserId': !exists(json, 'stratUserId') ? undefined : json['stratUserId'],
        'strategyId': !exists(json, 'strategyId') ? undefined : json['strategyId'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
    };
}

export function JsonStratUserToJSON(value?: JsonStratUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'companyDepartmentId': value.companyDepartmentId,
        'companyPositionId': value.companyPositionId,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'departmentId': value.departmentId,
        'dueDate': value.dueDate === undefined ? undefined : (value.dueDate.toISOString().substr(0,10)),
        'notifFlag': value.notifFlag,
        'parentUserId': value.parentUserId,
        'positionId': value.positionId,
        'roleId': value.roleId,
        'rowNo': value.rowNo,
        'saveErrors': value.saveErrors === undefined ? undefined : ((value.saveErrors as Array<any>).map(JsonErrorInfoToJSON)),
        'stratUserId': value.stratUserId,
        'strategyId': value.strategyId,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
        'userId': value.userId,
    };
}

