export enum OnepagerEntityType {
    Purpose = 'purpose',
    Ambition = 'ambition',
    TeamAmbition = 'teamAmbition',
    CVB = 'cvb',
    Enabler = 'enabler',
    Pillar = 'pillar',
    PillarParent = 'pillarParent',
    PillarHeader = 'pillarHeader',
    PillarSuccessLook = 'pillarSuccessLook',
    Initiative = 'initiative',
    Comment = 'comment',

    // entities for ALTV2
    PillarCompany = 'pillarCompany',
    PillarTeam = 'pillarTeam',
    AmbitionCompany = 'ambitionCompany',
    AmbitionTeam = 'ambitionTeam'
}

export type OnepagerEntityIndex = {
    parentEntity?: OnepagerEntityType;
    parentEntityIndex?: number;
    entity: OnepagerEntityType;
    entityIndex?: number;
}
