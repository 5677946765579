import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {useAppTranslation} from "../../../services/i18n";
import {
    GetStratPillarListUsingGETPivotModeEnum,
    JsonPoplistItem, JsonStratEnabler,
    JsonStratEnablerInfo,
    JsonStratPillarInfo,
    JsonStratPillarInfoStatusEnum
} from "../../../generated-api";
import ClosableModal, {ModalButtonActionEnum} from "../../../components/layout/ClosableModal";
import {fetchEnablerInfo, fetchPillars} from "../../../store/strategy";
import {useAppDispatch} from "../../../store";
import styles from "../../../assets/styles/stratairu.module.css";
import {useSelector} from "react-redux";
import {selectCodebooks, selectStrategyContext} from "../../../store/selectors";
import {ReactComponent as HomeIcon} from '../../../assets/images/stratairu/home.svg';
import StratairuSanitizedText from "./StratairuSanitizedText";
import {useOnePager} from "../../../hooks/useOnePager";

const RecursiveList = ({ parentPillarId, parentEnablerId, pillars, index, onSelect }: { parentPillarId?: number, parentEnablerId?:number, pillars?: JsonStratPillarInfo[], index: number, onSelect: () => void }) => {
    const filteredPillars = (pillars || []).filter(p => (parentPillarId && p.parentPillarId === parentPillarId) || (parentEnablerId && p.parentEnablerId === parentEnablerId))
    return <>
        {filteredPillars.map((p) => {
            const hasSubPillars = !!pillars?.find(sp => sp.parentPillarId === p.pillarId);
            return p.status === JsonStratPillarInfoStatusEnum.Active ? <>
                <div className={styles.simpleListRow} style={{ paddingLeft: (20 * index) + 'px' }}>
                    <PillarStructureItem item={p} key={index} onSelect={onSelect}/>
                </div>
                { hasSubPillars && <RecursiveList parentPillarId={p.pillarId} pillars={pillars} index={index + 1} onSelect={onSelect}/> }
            </> : <></>
        })}
        </>;
}

export interface PillarStructurePivot {
    pivotPillarId?: number | undefined;
    pivotEnablerId?: number | undefined;
    pivotMode: GetStratPillarListUsingGETPivotModeEnum | undefined;
}
interface Props {
    pivot: PillarStructurePivot | undefined;
    onClose: () => void;
}

export const PillarStructureItem = ({ item, onSelect, disableNavigate }: { item: JsonStratPillarInfo | JsonStratEnablerInfo, onSelect: () => void, disableNavigate?: boolean }) => {

    const codebooks = useSelector(selectCodebooks);
    const context = useSelector(selectStrategyContext);
    const { navigateOnePagerById } = useOnePager();

    const departmentId = item.companyDepartmentId || item.departmentId;
    const departmentName = (item.companyDepartmentId ? context?.companyDepartments : codebooks['companyDepartment'])
        .find((item: JsonPoplistItem) => item.value === departmentId || item.value === departmentId + '' )?.label;

    const positionId = item.companyPositionId || item.positionId;
    const positionName = (item.companyPositionId ? context?.companyPositions : codebooks['companyPosition'])
        .find((item: JsonPoplistItem) => item.value === positionId || item.value === positionId + '' )?.label;

    const handleNavigateItem = useCallback(async () => {
        if (onSelect) {
            onSelect();
        }
        if (!disableNavigate) {
            await navigateOnePagerById(item.onepagerId!);
        }
    }, [item, navigateOnePagerById, onSelect]);

    return (<div className={styles.cardContainer} onClick={handleNavigateItem}>
        <div className={styles.content} style={{ marginRight: '0px' }}>
            <div className={styles.icon}>
                { 1 === 1 && <HomeIcon /> }
                { /* 2 !== 1 && <ProfileIcon />*/ }
            </div>
            <div className={styles.info2}>
                { (item.firstName || item.lastName) && <><b>{ item.firstName } { item.lastName }</b>, { departmentName }<br/></> }
                <StratairuSanitizedText text={ item?.description } />
            </div>
        </div>
    </div>)
}

const StratairuOnePagerPillarStructureModal = ({ pivot, onClose }: Props) => {

    const t = useAppTranslation();

    const dispatch = useAppDispatch();

    const [drillUpPillars, setDrillUpPillars] = useState<JsonStratPillarInfo[]|undefined>(undefined);
    const [drillUpParentEnabler, setDrillUpParentEnabler] = useState<JsonStratEnablerInfo|undefined>(undefined);

    const [drillDownPillars, setDrillDownPillars] = useState<JsonStratPillarInfo[]|undefined>(undefined);
    const [drillDownRootPillar, setDrillDownRootPillar] = useState<JsonStratPillarInfo|undefined>(undefined);
    const [drillDownRootEnabler, setDrillDownRootEnabler] = useState<JsonStratEnablerInfo|undefined>(undefined);


    useEffect(() => {
        // load only when pivot is set
        if (!pivot) {
            return;
        }
        dispatch(fetchPillars({
            pivotPillarId: pivot?.pivotPillarId,
            pivotEnablerId: pivot?.pivotEnablerId,
            pivotMode: pivot?.pivotMode
        })).then((result) => {
            if (!('error' in result)) {
                if (pivot?.pivotMode === GetStratPillarListUsingGETPivotModeEnum.Up) {
                    const pillars = result.payload.data || [];
                    pillars.sort((a, b) => (a.pillarPath || '').split('/').length < (b.pillarPath || '').split('/').length ? -1 : 1);
                    console.log({ pillars });
                    if (pillars && pillars[0] && pillars[0].parentEnablerId) {
                        dispatch(fetchEnablerInfo(pillars[0].parentEnablerId))
                            .then((enablerResult) => {
                                if (!('error' in enablerResult)) {
                                    setDrillUpParentEnabler(enablerResult.payload.data);
                                }
                            });
                    } else {
                        setDrillUpParentEnabler(undefined);
                    }
                    setDrillUpPillars(pillars);
                }
                if (pivot?.pivotMode === GetStratPillarListUsingGETPivotModeEnum.Down) {
                    const pillars = result.payload.data || [];
                    setDrillDownPillars(pillars);
                    setDrillDownRootPillar(undefined);
                    setDrillDownRootEnabler(undefined);
                    if (pivot?.pivotPillarId) {
                        const rootPillar = pillars?.find(p => p.pillarId === pivot?.pivotPillarId);
                        setDrillDownRootPillar(rootPillar);
                    }
                    if (pivot?.pivotEnablerId) {
                        dispatch(fetchEnablerInfo(pivot?.pivotEnablerId))
                            .then((enablerResult) => {
                                if (!('error' in enablerResult)) {
                                    setDrillDownRootEnabler(enablerResult.payload.data);
                                }
                            });
                    }
                }
            }
        });
    }, [pivot, dispatch])

    return (
        <>
            { pivot &&
                <ClosableModal open={!!pivot} title={t('stratairu.titles.verticalSynchronisation')} onClose={onClose} buttons={[]}>
                    { pivot.pivotMode === GetStratPillarListUsingGETPivotModeEnum.Up &&
                        <div className={styles.simpleList} style={{ minWidth: '500px', boxSizing: 'border-box' }}>
                            { drillUpParentEnabler &&
                                <div className={styles.simpleListRow} style={{ paddingLeft: '0px' }}>
                                    <PillarStructureItem item={drillUpParentEnabler} onSelect={onClose}/>
                                </div>
                            }
                            { drillUpPillars?.map((pillar, index) =>
                                <div className={styles.simpleListRow} style={{ paddingLeft: (20 * (index + (drillUpParentEnabler ? 1 : 0))) + 'px' }}>
                                    <PillarStructureItem item={pillar} onSelect={onClose}/>
                                </div>
                            )}
                        </div>
                    }
                    { pivot.pivotMode === GetStratPillarListUsingGETPivotModeEnum.Down &&
                        <div className={styles.simpleList}  style={{ minWidth: '500px', boxSizing: 'border-box'}}>
                            { /* <div className={styles.simpleListRow} style={{ paddingLeft: '0px' }}>{ drillDownRootEnabler?.description || drillDownRootPillar?.description }</div> */ }
                            { (drillDownRootEnabler || drillDownRootPillar) && <PillarStructureItem item={(drillDownRootEnabler || drillDownRootPillar)!} onSelect={onClose}/> }
                            <RecursiveList parentEnablerId={pivot.pivotEnablerId} parentPillarId={pivot.pivotPillarId} pillars={drillDownPillars} index={1} onSelect={onClose}/>
                        </div>
                    }
                </ClosableModal>
            }
        </>
    );

}

export default StratairuOnePagerPillarStructureModal;
