import {OnepagerEntityType} from "../model/onepager";
import {CommonActionsType} from "../model/form";
import {
    JsonStratCvb, JsonStratCvbStatusEnum,
    JsonStratEnabler, JsonStratEnablerStatusEnum,
    JsonStratOnepager,
    JsonStratOnepagerInfo,
    JsonStratOnepagerInfoStatusEnum,
    JsonStratOnepagerStatusEnum, JsonStratPillar, JsonStratPillarStatusEnum
} from "../generated-api";
import {MAX_CVBS, MAX_ENABLERS, MAX_PILLARS} from "../pages/stratairu/StratairuOnePagerPage";

export enum OnePagerRowSource {
    SELF = 'Self',
    PARENT = 'Parent',
    ROOT = 'Root'
}

export enum OnePagerHeaderTitleColor {
    DARK = 'Dark',
    LIGHT = 'Light'
}

export interface OnePagerRowConfig {
    source: OnePagerRowSource;
    title?: string;
    entity: OnepagerEntityType;
    rowActions?: CommonActionsType[];
    actions?: CommonActionsType[];
    readonly: boolean;
    titleColor?: OnePagerHeaderTitleColor;
}

export const getOnePagerContentDef = (user: JsonStratOnepagerInfo, onepager: JsonStratOnepager, self: JsonStratOnepagerInfo, parent: JsonStratOnepagerInfo, root: JsonStratOnepagerInfo): OnePagerRowConfig[] => {
    console.log({ user, self, root, parent });
    if (!user || !self || !root) {
        return [];
    }
    const isEditable = user.userId === onepager.userId && onepager.status !== JsonStratOnepagerStatusEnum.Finished;
    const isRoot = self.onepagerId === root.onepagerId;
    const isFirstLevel = self.parentUserId === root.userId;
    const isOther = !isRoot && !isFirstLevel;

    let enableNewPillar = !onepager?.pillars || onepager?.pillars?.filter((pillar: JsonStratPillar) => pillar.status === JsonStratPillarStatusEnum.Active).length < MAX_PILLARS;
    let enableNewEnabler = !onepager?.enablers || onepager?.enablers?.filter((enabler: JsonStratEnabler) => enabler.status === JsonStratEnablerStatusEnum.Active).length < MAX_ENABLERS;
    let enableNewCVBs = !onepager?.cvbs || onepager?.cvbs?.filter((cvb: JsonStratCvb) => cvb.status === JsonStratCvbStatusEnum.Active).length < MAX_CVBS;

    console.log({ isEditable, isRoot, isFirstLevel });

    const cvbsActions = isEditable ? (enableNewCVBs ? [CommonActionsType.Create, CommonActionsType.Help] : [CommonActionsType.Help]) : [];
    const pillarsActions = isEditable ? (enableNewPillar ? [CommonActionsType.Create, CommonActionsType.Help] : [CommonActionsType.Help]) : [];
    const enablersActions = isEditable ? (enableNewEnabler ? [CommonActionsType.Create, CommonActionsType.Help] : [CommonActionsType.Help]) : [];

    if (isRoot) {
        return [
            { title: 'stratairu.onePager.root.purpose', titleColor: OnePagerHeaderTitleColor.DARK, entity: OnepagerEntityType.Purpose, source: OnePagerRowSource.SELF, rowActions: isEditable ? [CommonActionsType.Help] : [], actions: isEditable ? [CommonActionsType.Edit] : [], readonly: !isEditable },
            { title: 'stratairu.onePager.root.ambition', titleColor: OnePagerHeaderTitleColor.DARK, entity: OnepagerEntityType.Ambition, source: OnePagerRowSource.SELF, rowActions: isEditable ? [CommonActionsType.Help] : [], actions: isEditable ? [CommonActionsType.Edit] : [], readonly: !isEditable },
            { title: 'stratairu.onePager.root.pillarHeader', titleColor: undefined, entity: OnepagerEntityType.PillarHeader, source: OnePagerRowSource.SELF, rowActions: undefined, actions: isEditable ? [CommonActionsType.Help] : [], readonly: true },
            { title: 'stratairu.onePager.root.pillar', titleColor: OnePagerHeaderTitleColor.DARK, entity: OnepagerEntityType.Pillar, source: OnePagerRowSource.SELF, rowActions: pillarsActions, actions: isEditable ? [CommonActionsType.Edit] : [], readonly: !isEditable },
            { title: 'stratairu.onePager.root.successLook', titleColor: OnePagerHeaderTitleColor.DARK, entity: OnepagerEntityType.PillarSuccessLook, source: OnePagerRowSource.SELF, rowActions: isEditable ? [CommonActionsType.Help] : [], actions: [], readonly: !isEditable },
            { title: 'stratairu.onePager.root.initiative', titleColor: OnePagerHeaderTitleColor.DARK, entity: OnepagerEntityType.Initiative, source: OnePagerRowSource.SELF, rowActions: isEditable ? [CommonActionsType.Help] : [], actions: [], readonly: !isEditable },
            { title: 'stratairu.onePager.root.enablers', titleColor: OnePagerHeaderTitleColor.DARK, entity: OnepagerEntityType.Enabler, source: OnePagerRowSource.SELF, rowActions: enablersActions, actions: [], readonly: !isEditable },
            { title: 'stratairu.onePager.root.cvbs', titleColor: OnePagerHeaderTitleColor.DARK, entity: OnepagerEntityType.CVB, source: OnePagerRowSource.SELF, rowActions: cvbsActions, actions: [], readonly: !isEditable }
        ]
    }
    if (isFirstLevel) {
        return [
            { title: 'stratairu.onePager.firstLevel.companyPurpose', titleColor: OnePagerHeaderTitleColor.LIGHT, entity: OnepagerEntityType.Purpose, source: OnePagerRowSource.ROOT, rowActions: isEditable ? [CommonActionsType.Help] : [], actions: [], readonly: true },
            { title: 'stratairu.onePager.firstLevel.companyAmbition', titleColor: OnePagerHeaderTitleColor.LIGHT, entity: OnepagerEntityType.Ambition, source: OnePagerRowSource.ROOT, rowActions: isEditable ? [CommonActionsType.Help] : [], actions: [], readonly: true },
            { title: 'stratairu.onePager.firstLevel.ambition', titleColor: OnePagerHeaderTitleColor.DARK, entity: OnepagerEntityType.Ambition, source: OnePagerRowSource.SELF, rowActions: isEditable ? [CommonActionsType.Help] : [], actions: isEditable ? [CommonActionsType.Edit] : [], readonly: !isEditable },
            { title: 'stratairu.onePager.firstLevel.pillarHeader', titleColor: undefined, entity: OnepagerEntityType.PillarHeader, source: OnePagerRowSource.SELF, rowActions: undefined, actions: isEditable ? [CommonActionsType.Help] : [], readonly: true },
            { title: 'stratairu.onePager.firstLevel.parentPillar', titleColor: OnePagerHeaderTitleColor.LIGHT, entity: OnepagerEntityType.PillarParent, source: OnePagerRowSource.SELF, rowActions: isEditable ? [CommonActionsType.Help] : [], actions: [], readonly: !isEditable },
            { title: 'stratairu.onePager.firstLevel.pillar', titleColor: OnePagerHeaderTitleColor.DARK, entity: OnepagerEntityType.Pillar, source: OnePagerRowSource.SELF, rowActions: pillarsActions, actions: isEditable ? [CommonActionsType.Edit] : [], readonly: !isEditable },
            { title: 'stratairu.onePager.firstLevel.successLook', titleColor: OnePagerHeaderTitleColor.DARK, entity: OnepagerEntityType.PillarSuccessLook, source: OnePagerRowSource.SELF, rowActions: isEditable ? [CommonActionsType.Help] : [], actions: [], readonly: !isEditable },
            { title: 'stratairu.onePager.firstLevel.initiative', titleColor: OnePagerHeaderTitleColor.DARK, entity: OnepagerEntityType.Initiative, source: OnePagerRowSource.SELF, rowActions: isEditable ? [CommonActionsType.Help] : [], actions: [], readonly: !isEditable },
            { title: 'stratairu.onePager.firstLevel.enablers', titleColor: OnePagerHeaderTitleColor.DARK, entity: OnepagerEntityType.Enabler, source: OnePagerRowSource.SELF, rowActions: enablersActions, actions: [], readonly: !isEditable },
            { title: 'stratairu.onePager.firstLevel.cvbs', titleColor: OnePagerHeaderTitleColor.LIGHT, entity: OnepagerEntityType.CVB, source: OnePagerRowSource.ROOT, rowActions: isEditable ? [CommonActionsType.Help] : [], actions: [], readonly: true }
        ]
    }
    if (isOther) {
        return [
            { title: 'stratairu.onePager.other.companyPurpose', titleColor: OnePagerHeaderTitleColor.LIGHT, entity: OnepagerEntityType.Purpose, source: OnePagerRowSource.ROOT, rowActions: isEditable ? [CommonActionsType.Help] : [], actions: [], readonly: true },
            { title: 'stratairu.onePager.other.parentAmbition', titleColor: OnePagerHeaderTitleColor.LIGHT, entity: OnepagerEntityType.Ambition, source: OnePagerRowSource.PARENT, rowActions: isEditable ? [CommonActionsType.Help] : [], actions: [], readonly: true },
            { title: 'stratairu.onePager.other.ambition', titleColor: OnePagerHeaderTitleColor.DARK, entity: OnepagerEntityType.Ambition, source: OnePagerRowSource.SELF, rowActions: isEditable ? [CommonActionsType.Help] : [], actions: isEditable ? [CommonActionsType.Edit] : [], readonly: !isEditable },
            { title: 'stratairu.onePager.other.pillarHeader', titleColor: undefined, entity: OnepagerEntityType.PillarHeader, source: OnePagerRowSource.SELF, rowActions: undefined, actions: isEditable ? [CommonActionsType.Help] : [], readonly: true },
            { title: 'stratairu.onePager.other.parentPillar', titleColor: OnePagerHeaderTitleColor.LIGHT, entity: OnepagerEntityType.PillarParent, source: OnePagerRowSource.SELF, rowActions: isEditable ? [CommonActionsType.Help] : [], actions: [], readonly: !isEditable },
            { title: 'stratairu.onePager.other.pillar', titleColor: OnePagerHeaderTitleColor.DARK, entity: OnepagerEntityType.Pillar, source: OnePagerRowSource.SELF, rowActions: pillarsActions, actions: isEditable ? [CommonActionsType.Edit] : [], readonly: !isEditable },
            { title: 'stratairu.onePager.other.successLook', titleColor: OnePagerHeaderTitleColor.DARK, entity: OnepagerEntityType.PillarSuccessLook, source: OnePagerRowSource.SELF, rowActions: isEditable ? [CommonActionsType.Help] : [], actions: [], readonly: !isEditable },
            { title: 'stratairu.onePager.other.initiative', titleColor: OnePagerHeaderTitleColor.DARK, entity: OnepagerEntityType.Initiative, source: OnePagerRowSource.SELF, rowActions: isEditable ? [CommonActionsType.Help] : [], actions: [], readonly: !isEditable },
            { title: 'stratairu.onePager.other.enablers', titleColor: OnePagerHeaderTitleColor.DARK, entity: OnepagerEntityType.Enabler, source: OnePagerRowSource.SELF, rowActions: enablersActions, actions: [], readonly: !isEditable },
            { title: 'stratairu.onePager.other.cvbs', titleColor: OnePagerHeaderTitleColor.LIGHT, entity: OnepagerEntityType.CVB, source: OnePagerRowSource.ROOT, rowActions: isEditable ? [CommonActionsType.Help] : [], actions: [], readonly: true }
        ]
    }
    return [];
}

export const getOnePagerNamespace = (user: JsonStratOnepagerInfo, onepager: JsonStratOnepager, self: JsonStratOnepagerInfo, parent: JsonStratOnepagerInfo, root: JsonStratOnepagerInfo): string => {
    if (!user || !self || !root) {
        return '';
    }
    if (self.onepagerId === root.onepagerId) {
        return 'root';
    }
    if (self.parentUserId === root.userId) {
        return 'firstLevel';
    }
    return 'other';
}
