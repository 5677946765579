import {
    JsonStratCvb,
    JsonStratCvbStatusEnum,
    JsonStratEnabler,
    JsonStratEnablerStatusEnum,
    JsonStratInitiative, JsonStratInitiativeStatusEnum,
    JsonStratOnepager,
    JsonStratPillar,
    JsonStratPillarStatusEnum, JsonStratSuccessLook, JsonStratSuccessLookStatusEnum
} from "../generated-api";
import {OnepagerEntityIndex, OnepagerEntityType} from "../model/onepager";
import {
    stratCvbFactory,
    stratEnablerFactory,
    stratInitiativeFactory,
    stratPillarFactory,
    stratSuccessLookFactory
} from "../model/factories";
import {CommonActionsType} from "../model/form";

export const processAddOnePagerEntity = (onepager: JsonStratOnepager, entityIndex: OnepagerEntityIndex) => {
    const newOnepager = { ...onepager };

    if (entityIndex.entity === OnepagerEntityType.CVB) {
        const cvbEntity: JsonStratCvb = stratCvbFactory(newOnepager.onepagerId!);
        if (newOnepager.cvbs) {
            console.log('ADD-CVB');
            newOnepager.cvbs.push(cvbEntity);
        } else {
            newOnepager.cvbs = [cvbEntity];
        }
    }

    if ([OnepagerEntityType.Pillar, OnepagerEntityType.PillarCompany].includes(entityIndex.entity)) {
        const pillarEntity: JsonStratPillar = stratPillarFactory(newOnepager.onepagerId!);
        if (newOnepager.pillars) {
            newOnepager.pillars.push(pillarEntity);
        } else {
            newOnepager.pillars = [pillarEntity];
        }
    }

    if (entityIndex.entity === OnepagerEntityType.PillarSuccessLook) {
        const parentPillar: JsonStratPillar = newOnepager.pillars?.find((val, index) => index === entityIndex.parentEntityIndex)!;
        const successLookEntity: JsonStratSuccessLook = stratSuccessLookFactory(parentPillar.pillarId);
        if (parentPillar.successLooks) {
            parentPillar.successLooks.push(successLookEntity);
        } else {
            parentPillar.successLooks = [successLookEntity];
        }
    }

    if (entityIndex.entity === OnepagerEntityType.Initiative) {
        const parentPillar: JsonStratPillar = newOnepager.pillars?.find((val, index) => index === entityIndex.parentEntityIndex)!;
        const initiativeEntity: JsonStratInitiative = stratInitiativeFactory(parentPillar.pillarId);
        if (parentPillar.initiatives) {
            parentPillar.initiatives.push(initiativeEntity);
        } else {
            parentPillar.initiatives = [initiativeEntity];
        }
    }

    if (entityIndex.entity === OnepagerEntityType.Enabler) {
        const enablerEntity: JsonStratEnabler = stratEnablerFactory(newOnepager.onepagerId!);
        if (newOnepager.enablers) {
            newOnepager.enablers.push(enablerEntity);
        } else {
            newOnepager.enablers = [enablerEntity];
        }
    }

    return newOnepager;
}

export const processChangeOnePagerEntity = (onepager: JsonStratOnepager, entityIndex: OnepagerEntityIndex, data: string) => {
    const newOnepager = { ...onepager };

    if (entityIndex.entity === OnepagerEntityType.Purpose) {
        newOnepager.purpose = data;
    }

    if (entityIndex.entity === OnepagerEntityType.Ambition) {
        newOnepager.ambition = data;
    }

    if (entityIndex.entity === OnepagerEntityType.Pillar) {
        const pillarEntity = newOnepager.pillars?.find((enabler, index) => index === entityIndex.entityIndex);
        if (pillarEntity) {
            pillarEntity.description = data;
        }
    }

    if ([OnepagerEntityType.PillarCompany, OnepagerEntityType.PillarTeam].includes(entityIndex.entity)) {
        const pillarEntity = newOnepager.pillars?.find((enabler, index) => index === entityIndex.entityIndex);
        if (pillarEntity) {
            let result = undefined;
            try {
                result = JSON.parse(pillarEntity.description || '');
            } catch (e) {
                result = undefined;
            }
            if (result?.length) {
                pillarEntity.description = JSON.stringify(entityIndex.entity === OnepagerEntityType.PillarCompany ? [data, result[1]] : [result[0], data]);
            } else {
                pillarEntity.description = JSON.stringify(entityIndex.entity === OnepagerEntityType.PillarCompany ? [data, ''] : ['', data]);
            }
        }
    }

    if (entityIndex.entity === OnepagerEntityType.PillarSuccessLook) {
        const parentPillar = newOnepager.pillars?.find((val, index) => index === entityIndex.parentEntityIndex);
        const successLookEntity: JsonStratSuccessLook = parentPillar?.successLooks?.find((val, index) => index === entityIndex.entityIndex)!;
        if (successLookEntity) {
            successLookEntity.description = data;
        }
    }

    if (entityIndex.entity === OnepagerEntityType.Initiative) {
        const parentPillar: JsonStratPillar = newOnepager.pillars?.find((val, index) => index === entityIndex.parentEntityIndex)!;
        const initiativeEntity: JsonStratInitiative = parentPillar.initiatives?.find((val, index) => index === entityIndex.entityIndex)!;
        if (initiativeEntity) {
            initiativeEntity.description = data;
        }
    }

    if (entityIndex.entity === OnepagerEntityType.Enabler) {
        const enablerEntity = newOnepager.enablers?.find((enabler, index) => index === entityIndex.entityIndex);
        if (enablerEntity) {
            enablerEntity.description = data;
        }
    }

    if (entityIndex.entity === OnepagerEntityType.CVB) {
        const cvbEntity = newOnepager.cvbs?.find((cvb, index) => index === entityIndex.entityIndex);
        if (cvbEntity) {
            cvbEntity.description = data;
        }
    }

    // Workzone updates
    if ([OnepagerEntityType.AmbitionCompany, OnepagerEntityType.AmbitionTeam].includes(entityIndex.entity)) {
        let result = undefined;
        try {
            result = JSON.parse(newOnepager.ambition || '');
        } catch (e) {
            result = undefined;
        }
        if (result?.length) {
            newOnepager.ambition = JSON.stringify(entityIndex.entity === OnepagerEntityType.AmbitionCompany ? [data, result[1]] : [result[0], data]);
        } else {
            newOnepager.ambition = JSON.stringify(entityIndex.entity === OnepagerEntityType.AmbitionCompany ? [data, ''] : ['', data]);
        }
    }

    return newOnepager;
}

export const processDeleteOnePagerEntity = (onepager: JsonStratOnepager, entityIndex: OnepagerEntityIndex) => {
    const newOnepager = {...onepager};

    if ([OnepagerEntityType.Pillar, OnepagerEntityType.PillarCompany].includes(entityIndex.entity)) {
        const pillarEntity = newOnepager.pillars?.find((cvb, index) => index === entityIndex.entityIndex);
        if (pillarEntity) {
            if (pillarEntity.pillarId) {
                newOnepager.pillars = newOnepager.pillars?.map(pillar => pillar !== pillarEntity ? pillar : { ...pillar, status: JsonStratPillarStatusEnum.Deleted })
            } else {
                newOnepager.pillars = newOnepager.pillars?.filter(pillar => pillar !== pillarEntity);
            }
        }
    }

    if (entityIndex.entity === OnepagerEntityType.Initiative) {
        const parentPillar: JsonStratPillar = newOnepager.pillars?.find((val, index) => index === entityIndex.parentEntityIndex)!;
        const initiativeEntity: JsonStratInitiative = parentPillar.initiatives?.find((val, index) => index === entityIndex.entityIndex)!;
        if (parentPillar && initiativeEntity) {
            if (initiativeEntity.initiativeId) {
                parentPillar.initiatives = parentPillar.initiatives?.map(initiative => initiative !== initiativeEntity ? initiative : { ...initiativeEntity, status: JsonStratInitiativeStatusEnum.Deleted })
            } else {
                parentPillar.initiatives = parentPillar.initiatives?.filter(initiative => initiative !== initiativeEntity);
            }
        }
    }

    if (entityIndex.entity === OnepagerEntityType.PillarSuccessLook) {
        const parentPillar: JsonStratPillar = newOnepager.pillars?.find((val, index) => index === entityIndex.parentEntityIndex)!;
        const successLookEntity: JsonStratSuccessLook = parentPillar.successLooks?.find((val, index) => index === entityIndex.entityIndex)!;
        if (parentPillar && successLookEntity) {
            if (successLookEntity.successLookId) {
                parentPillar.successLooks = parentPillar.successLooks?.map(successLook => successLook !== successLookEntity ? successLook : { ...successLookEntity, status: JsonStratSuccessLookStatusEnum.Deleted })
            } else {
                parentPillar.successLooks = parentPillar.successLooks?.filter(successLook => successLook !== successLookEntity);
            }
        }
    }

    if (entityIndex.entity === OnepagerEntityType.Enabler) {
        const enablerEntity = newOnepager.enablers?.find((cvb, index) => index === entityIndex.entityIndex);
        if (enablerEntity) {
            if (enablerEntity.enablerId) {
                newOnepager.enablers = newOnepager.enablers?.map(enabler => enabler !== enablerEntity ? enabler : { ...enabler, status: JsonStratEnablerStatusEnum.Deleted })
            } else {
                newOnepager.enablers = newOnepager.enablers?.filter(enabler => enabler !== enablerEntity);
            }
        }
    }

    if (entityIndex.entity === OnepagerEntityType.CVB) {
        const cvbEntity = newOnepager.cvbs?.find((cvb, index) => index === entityIndex.entityIndex);
        if (cvbEntity) {
            if (cvbEntity.cvbId) {
                newOnepager.cvbs = newOnepager.cvbs?.map(cvb => cvb !== cvbEntity ? cvb : { ...cvb, status: JsonStratCvbStatusEnum.Deleted })
            } else {
                newOnepager.cvbs = newOnepager.cvbs?.filter(cvb => cvb !== cvbEntity);
            }
        }
    }

    return newOnepager;
}

export const processUpdateOnePagerParentPillar = (onepager: JsonStratOnepager, entityIndex: OnepagerEntityIndex, data: Partial<JsonStratPillar>) => {
    const newOnepager = { ...onepager };

    if (entityIndex.entity === OnepagerEntityType.Pillar) {
        const pillarEntity = newOnepager.pillars?.find((enabler, index) => index === entityIndex.entityIndex);
        if (pillarEntity) {
            pillarEntity.parentPillarId = data.parentPillarId;
            pillarEntity.parentEnablerId = data.parentEnablerId;
        }
    }

    return newOnepager;
}

export const fixOnePagerData = (onepager?: JsonStratOnepager) => {
    if (!onepager) {
        return undefined;
    }
    return {
        ...onepager,
        pillars: onepager.pillars?.map((pillar: JsonStratPillar, index: number) => ({
            ...pillar,
            initiatives: pillar.initiatives?.map((initiative: JsonStratInitiative, initiativeIndex: number) => ({ ...initiative, orderNo: initiativeIndex})),
            successLooks: pillar.successLooks?.map((successLook: JsonStratSuccessLook, successLookIndex: number) => ({ ...successLook, orderNo: successLookIndex})),
            orderNo: index
        })),
        enablers: onepager.enablers?.map((enabler: JsonStratEnabler, index: number) => ({ ...enabler, orderNo: index})),
        cvbs: onepager.cvbs?.map((cvb: JsonStratCvb, index: number) => ({ ...cvb, orderNo: index })),
        allowedActions: undefined,
        changes: undefined
    }
}

export const parseArray = (serializedArray?: string, index?: number) => {
    let description = '';
    if (serializedArray) {
        try {
            const result = JSON.parse(serializedArray || '');
            if (result?.length && result.length === 2) {
                description = result[index || 0];
            }
        } catch(e) {
        }
    }
    return description;
}
