/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';
import {
    JsonStratCvb,
    JsonStratCvbFromJSON,
    JsonStratCvbFromJSONTyped,
    JsonStratCvbToJSON,
} from './JsonStratCvb';
import {
    JsonStratEnabler,
    JsonStratEnablerFromJSON,
    JsonStratEnablerFromJSONTyped,
    JsonStratEnablerToJSON,
} from './JsonStratEnabler';
import {
    JsonStratPillar,
    JsonStratPillarFromJSON,
    JsonStratPillarFromJSONTyped,
    JsonStratPillarToJSON,
} from './JsonStratPillar';

/**
 * 
 * @export
 * @interface JsonStratOnepager
 */
export interface JsonStratOnepager {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonStratOnepager
     */
    allowedActions?: Array<JsonStratOnepagerAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonStratOnepager
     */
    ambition?: string;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonStratOnepager
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {number}
     * @memberof JsonStratOnepager
     */
    companyId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonStratOnepager
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonStratOnepager
     */
    createdBy?: number;
    /**
     * 
     * @type {Array<JsonStratCvb>}
     * @memberof JsonStratOnepager
     */
    cvbs?: Array<JsonStratCvb>;
    /**
     * 
     * @type {Array<JsonStratEnabler>}
     * @memberof JsonStratOnepager
     */
    enablers?: Array<JsonStratEnabler>;
    /**
     * 
     * @type {number}
     * @memberof JsonStratOnepager
     */
    onepagerId?: number;
    /**
     * 
     * @type {Array<JsonStratPillar>}
     * @memberof JsonStratOnepager
     */
    pillars?: Array<JsonStratPillar>;
    /**
     * 
     * @type {string}
     * @memberof JsonStratOnepager
     */
    purpose?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonStratOnepager
     */
    status?: JsonStratOnepagerStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof JsonStratOnepager
     */
    strategyId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonStratOnepager
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonStratOnepager
     */
    updatedBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratOnepager
     */
    userId?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonStratOnepagerAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonStratOnepagerStatusEnum {
    New = 'NEW',
    Draft = 'DRAFT',
    Public = 'PUBLIC',
    Finished = 'FINISHED'
}

export function JsonStratOnepagerFromJSON(json: any): JsonStratOnepager {
    return JsonStratOnepagerFromJSONTyped(json, false);
}

export function JsonStratOnepagerFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonStratOnepager {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'ambition': !exists(json, 'ambition') ? undefined : json['ambition'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'cvbs': !exists(json, 'cvbs') ? undefined : ((json['cvbs'] as Array<any>).map(JsonStratCvbFromJSON)),
        'enablers': !exists(json, 'enablers') ? undefined : ((json['enablers'] as Array<any>).map(JsonStratEnablerFromJSON)),
        'onepagerId': !exists(json, 'onepagerId') ? undefined : json['onepagerId'],
        'pillars': !exists(json, 'pillars') ? undefined : ((json['pillars'] as Array<any>).map(JsonStratPillarFromJSON)),
        'purpose': !exists(json, 'purpose') ? undefined : json['purpose'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'strategyId': !exists(json, 'strategyId') ? undefined : json['strategyId'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
    };
}

export function JsonStratOnepagerToJSON(value?: JsonStratOnepager | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'ambition': value.ambition,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'companyId': value.companyId,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'cvbs': value.cvbs === undefined ? undefined : ((value.cvbs as Array<any>).map(JsonStratCvbToJSON)),
        'enablers': value.enablers === undefined ? undefined : ((value.enablers as Array<any>).map(JsonStratEnablerToJSON)),
        'onepagerId': value.onepagerId,
        'pillars': value.pillars === undefined ? undefined : ((value.pillars as Array<any>).map(JsonStratPillarToJSON)),
        'purpose': value.purpose,
        'status': value.status,
        'strategyId': value.strategyId,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
        'userId': value.userId,
    };
}

