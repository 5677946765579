import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {API_CONFIG} from '../app/api-config';
import {RegistrationFormData} from '../forms/RegistrationForm';
import {
	AuthControllerApi,
	JsonAuthUserInfo,
	JsonLoginRequest,
	JsonStrategyInfo, JsonUpdateUserRequest,
	JsonUserSexEnum, UserControllerApi
} from '../generated-api';

import {fetchCodebooks, fetchCompaniesCombo, fetchSeriesCombo} from "./codebooks";
import {AppThunkConfig} from "./index";
import {isLiveSite} from "../app/config";
import {RootState} from "../index";

const authApi = new AuthControllerApi(API_CONFIG);
const userApi = new UserControllerApi(API_CONFIG);

const fetchAuthUser = createAsyncThunk('auth/login', async (_dummy, thunkApi) => {
	try {
		const result = await authApi.getInfoUsingGET()
		return {
			user: result.data,
			loading: false,
		};
	} catch (e: any) {
		thunkApi.rejectWithValue({
			user: null,
			loading: false,
		});
	}
});

const fetchAuthLoginCredentials = createAsyncThunk('auth/login', async (credentials: JsonLoginRequest) => {
	const result = await authApi.loginUsingPOST({
		auth: credentials
	});
	return {
		user: result.data,
		loading: false,
	};
});

export const processLogin = createAsyncThunk<boolean | string, string|JsonLoginRequest|undefined, AppThunkConfig>('process/login',async (data: string|JsonLoginRequest|undefined, thunkAPI) => {
	let res = null;
	if (!data) {
		res = await thunkAPI.dispatch(fetchAuthUser());
	} else if (typeof data === 'string') {
		//throw new Error('Not implemented!');
	} else if ('email' in data) {
		res = await thunkAPI.dispatch(fetchAuthLoginCredentials(data));
	} else {
		//throw new Error();
	}

	if (!res || 'error' in res || !res.payload?.user) {
		throw new Error(res && 'error' in res ? res.error.message as string : 'GENERAL_ERROR')
	}

	// user related data initialization
	if (res.payload?.user?.user?.email && !isLiveSite) {
		await thunkAPI.dispatch(fetchCodebooks());
		await thunkAPI.dispatch(fetchSeriesCombo());
		await thunkAPI.dispatch(fetchCompaniesCombo());
	}
	return true;
});

export const fetchAuthPing = createAsyncThunk('process/ping',async () => {
	return authApi.pingUsingGET();
});


export const fetchAuthLogout = createAsyncThunk('auth/logout', async (_) => {
	await authApi.logoutUsingGET();
	window.location.reload(); // destroy all js data (store etc.)
});

export const fetchRegistrationInfo = createAsyncThunk('auth/registration/info', async (serieGuid: string) => {
	return await authApi.getSerieUserRegistrationUsingGET({ serieGuid });
});

export const fetchForgottenPassword = createAsyncThunk('auth/forgotten-password', async (credentials: JsonLoginRequest) => {
	return await authApi.forgottenPasswordUsingPOST({
		auth: credentials
	})
});

export const saveChangedPassword = createAsyncThunk('auth/change-password', async (credentials: JsonUpdateUserRequest) => {
	return await authApi.changePasswordUsingPOST({
		auth: credentials
	})
});

export const saveRegistration = createAsyncThunk('auth/registration/save', async (params: {registrationData: RegistrationFormData, serieGuid?: string, clientGuid?: string }) => {
	return await authApi.saveSerieUserRegistrationUsingPOST({ 
		json: {
			clientGuid: params.clientGuid,
			serieGuid: params.serieGuid,
			user: {
				email: params.registrationData.email || undefined,
				firstName: params.registrationData.firstName || undefined,
				lastName: params.registrationData.lastName || undefined,
				sex: (params.registrationData.sex as JsonUserSexEnum) || undefined,
				newPassword: params.registrationData.newPassword || undefined,
				isNewsletter: params.registrationData.isNewsletter
			},
			serieUser: {
				positionId: params.registrationData.positionId || undefined,
				departmentId: params.registrationData.departmentId || undefined,
				companyPositionId: params.registrationData.companyPositionId || undefined,
				companyDepartmentId: params.registrationData.companyDepartmentId || undefined,
				companyBranchId: params.registrationData.companyBranchId || undefined,
				durationId: params.registrationData.durationId || undefined,
				sectorId: params.registrationData.sectorId || undefined
			}
		}
	});
});

export const fetchCurrentRelay = createAsyncThunk('auth/current-relay', async (clientGuid: string) => {
	return await authApi.getCurrentRelayByClientGuidUsingGET({ clientGuid });
});

export const fetchValidateEmail = createAsyncThunk('auth/validate-email', async (email: string) => {
	return await authApi.validateEmailUsingGET({ email });
});

export const fetchSerieUser = createAsyncThunk('auth/serie-user', async (clientGuid: string) => {
	return await authApi.getSerieUserByClientGuidUsingGET({ clientGuid });
});

export const switchFavourite = createAsyncThunk('auth/switch-favourite', async (onePagerId: number, thunkApi) => {
	const { user } = (thunkApi.getState() as RootState).auth;
	console.log('switchFavourite', { onePagerId, user });
	if (user?.userId) {
		const isCurrentlyFavourite = !!user.userData?.favouriteOnepagerIds?.find((id: number) => onePagerId === id);
		console.log('isCurrentlyFavourite', { isCurrentlyFavourite });
		const newFavouriteOnepagerIds = isCurrentlyFavourite ?
			(user.userData?.favouriteOnepagerIds || []).filter((id: number) => onePagerId !== id)
			: [onePagerId, ...(user.userData?.favouriteOnepagerIds || [])];
		console.log('user', { user });
		return await userApi.saveUserDataUsingPOST({ json: {
				...user,
				userData: {
					...(user.userData || {}),
					favouriteOnepagerIds: newFavouriteOnepagerIds
				}
			}
		});
	} else {
		throw new Error('User not logged');
	}
});

export const addVisited = createAsyncThunk('auth/add-visited', async (onePagerId: number, thunkApi) => {
	const { user } = (thunkApi.getState() as RootState).auth;
	console.log('addVisited', { onePagerId, user });
	if (user?.userId) {
		const newRecentOnepagerIds = (user.userData?.recentOnepagerIds || []).filter((id: number) => id !== onePagerId);
		newRecentOnepagerIds.unshift(onePagerId);
		return await userApi.saveUserDataUsingPOST({ json: {
				...user,
				userData: {
					...(user.userData || {}),
					recentOnepagerIds: newRecentOnepagerIds
				}
			}
		});
	} else {
		throw new Error('User not logged');
	}
});

export const fetchCurrentEpisode = createAsyncThunk('auth/fetch-current-episode', async (clientGuid: string) => {
	return await authApi.getCurrentEpisodeByClientGuidUsingGET({ clientGuid });
});

export interface AuthState {
	user: JsonAuthUserInfo['user'] | null;
	configuration: JsonAuthUserInfo['_configuration'] | null;
	strategies: JsonStrategyInfo[] | null;
	strategyRoles: JsonAuthUserInfo['strategyRoles'] | null;
}

const initialState: AuthState = {
	user: null,
	configuration: null,
	strategies: null,
	strategyRoles: null
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
	},
	extraReducers: builder => {
		builder.addCase(fetchAuthUser.fulfilled, (state, action) => {
			state.user = action.payload?.user?.user || null;
			state.configuration = action.payload?.user?._configuration || null;
			state.strategies = action.payload?.user?.strategies || null;
			state.strategyRoles = action.payload?.user?.strategyRoles || null;
		});
		builder.addCase(fetchAuthUser.rejected, (state, action) => {
			state.user = null;
			state.configuration = null;
		});
		builder.addCase(fetchAuthLogout.pending, (state, action) => initialState);

		builder.addCase(switchFavourite.fulfilled, (state, action) => {
			state.user = action.payload.data;
		});

		builder.addCase(addVisited.fulfilled, (state, action) => {
			state.user = action.payload.data;
		});

	}
});

export const authReducer = authSlice.reducer;
