import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '..';
import {API_CONFIG} from '../app/api-config';
import {
	AuthControllerApi,
	CompanyControllerApi,
	CompanyDepartmentControllerApi,
	CompanyPositionControllerApi,
	GetCompanyDepartmentListUsingGETStatusEnum,
	GetCompanyPositionListUsingGETStatusEnum,
	GetStratOnepagerListUsingGETPivotModeEnum,
	GetStratOnepagerListUsingGETRequest,
	GetStratPillarListUsingGETRequest,
	JsonCompany,
	JsonPoplistItem,
	JsonStratComment,
	JsonStratCommentInfo,
	JsonStrategy,
	JsonStratOnepager,
	JsonStratOnepagerInfo,
	JsonStratOnepagerStatusEnum,
	JsonStratPillar,
	StratCommentControllerApi,
	StrategyControllerApi,
	StratEnablerControllerApi,
	StratOnepagerControllerApi,
	StratPillarControllerApi
} from '../generated-api';
import {
	fixOnePagerData,
	processAddOnePagerEntity,
	processChangeOnePagerEntity,
	processDeleteOnePagerEntity,
	processUpdateOnePagerParentPillar
} from "../helpers/onepager";
import {OnepagerEntityIndex} from "../model/onepager";
import {OnepagersMenuEnum} from "../pages/stratairu/StratairuHomePage";

const authApi = new AuthControllerApi(API_CONFIG);
const stratOnepagerApi = new StratOnepagerControllerApi(API_CONFIG);
const strategyApi = new StrategyControllerApi(API_CONFIG);
const companyApi = new CompanyControllerApi(API_CONFIG);
const companyDepartmentsApi = new CompanyDepartmentControllerApi(API_CONFIG);
const companyPositionsApi = new CompanyPositionControllerApi(API_CONFIG);
const stratPillarApi = new StratPillarControllerApi(API_CONFIG);
const stratEnablerApi = new StratEnablerControllerApi(API_CONFIG);
const stratCommentApi = new StratCommentControllerApi(API_CONFIG);

export const fetchStrategyContext = createAsyncThunk('strategy/fetch-context', async (strategyId: number, thunkApi) => {

	const strategyResult = await strategyApi.getStrategyUsingGET({ strategyId });
	const strategy = ('error' in strategyResult) ? undefined : strategyResult.data;

	const companyResult = await companyApi.getCompanyUsingGET({ companyId: strategy?.companyId! });
	const company = ('error' in companyResult) ? undefined : companyResult.data;

	const companyDepartmentsResult = await companyDepartmentsApi.getCompanyDepartmentListUsingGET({ companyId: strategy?.companyId, status: GetCompanyDepartmentListUsingGETStatusEnum.Active });
	const companyDepartments = ('error' in companyDepartmentsResult) ? [] : (companyDepartmentsResult.data || []).map(department => ({ label: department.title, value: department.companyDepartmentId }) as JsonPoplistItem );;

	const companyPositionsResult = await companyPositionsApi.getCompanyPositionListUsingGET({ companyId: strategy?.companyId, status: GetCompanyPositionListUsingGETStatusEnum.Active });
	const companyPositions = ('error' in companyPositionsResult) ? [] : (companyPositionsResult.data || []).map(position => ({ label: position.title, value: position.companyPositionId }) as JsonPoplistItem );

	return {
		strategy,
		company,
		companyPositions,
		companyDepartments
	}
});

export const fetchDashboardOnepagers = createAsyncThunk('dashboard/fetch-onepagers', async (requestParameters: GetStratOnepagerListUsingGETRequest) => {
	return stratOnepagerApi.getStratOnepagerListUsingGET(requestParameters)
});

export const searchOnePagers = createAsyncThunk('dashboard/fetch', async (params: GetStratOnepagerListUsingGETRequest, thunkApi) => {
	return stratOnepagerApi.getStratOnepagerListUsingGET({ ...params, rows: params.rows || 10000});
});

export const fetchDashboard = createAsyncThunk('dashboard/fetch', async (params: { strategyId: number }, thunkApi) => {
	const { user } = (thunkApi.getState() as RootState).auth;
	if (user && params?.strategyId) {
		await thunkApi.dispatch(fetchDashboardOnepagers({
			strategyId: params.strategyId,
			pivotUserId: user.userId,
			pivotMode: GetStratOnepagerListUsingGETPivotModeEnum.Dashboard
		}));
		await thunkApi.dispatch(fetchDashboardOnepagers({
			strategyId: params.strategyId,
			pivotUserId: user.userId,
			pivotMode: GetStratOnepagerListUsingGETPivotModeEnum.DrillDown
		}));
	}
});

export const fetchOverview = createAsyncThunk('overview/fetch', async (params: { strategyId: number }, thunkApi) => {
	const { user } = (thunkApi.getState() as RootState).auth;
	if (user && params?.strategyId) {
		// fetch my level
		await thunkApi.dispatch(fetchDashboardOnepagers({
			strategyId: params.strategyId,
			pivotUserId: user.userId,
			pivotMode: GetStratOnepagerListUsingGETPivotModeEnum.SameLevel
		}));
		// fetch all from this strategy
		await thunkApi.dispatch(fetchDashboardOnepagers({
			strategyId: params.strategyId
		}));
		// fetch favourite
		await thunkApi.dispatch(fetchDashboardOnepagers({
			strategyId: params.strategyId,
			onepagerIds: user.userData?.favouriteOnepagerIds || []
		}));
	}
});

export const fetchOnePagerInfo = createAsyncThunk('onepager/fetch-info', async (onepagerId: number , thunkAPI) => {
	return stratOnepagerApi.getStratOnepagerUsingGET({ onepagerId });
});

export const fetchOnePager = createAsyncThunk('onepager/fetch', async (onepagerId: number , thunkAPI) => {
	const opResult = await stratOnepagerApi.getStratOnepagerUsingGET({ onepagerId });
	if ('error' in opResult) { return undefined; }
	const hierarchyResult = await stratOnepagerApi.getStratOnepagerListUsingGET({
		strategyId: (opResult as any).data.strategyId,
		pivotUserId: (opResult as any).data.userId,
		pivotMode: GetStratOnepagerListUsingGETPivotModeEnum.Dashboard
	})
	if ('error' in hierarchyResult) { return undefined; }
	const commentsResult = await stratCommentApi.getStratCommentListUsingGET({ onepagerId, rows: 10000 });
	if ('error' in commentsResult) { return undefined; }
	thunkAPI.dispatch(setOnepagerHash(opResult.data));
	return {
		data: opResult.data,
		hierarchyOnePagers: hierarchyResult.data,
		comments: commentsResult.data,
	};
});

export const fetchSerieOnePager = createAsyncThunk('onepager/fetch-standalone', async ({ clientGuid, loggedUserId }: {clientGuid: string, loggedUserId: number}, thunkAPI) => {
	const userSerieResult = await authApi.getSerieUserByClientGuidUsingGET({ clientGuid });
	if ('error' in userSerieResult) {
		return undefined;
	}
	const userId = userSerieResult?.data?.user?.userId;
	if (!userId || userId !== loggedUserId) {
		return undefined;
	}
	const serieId = userSerieResult?.data?.user?.serieId;
	if (!serieId) {
		return undefined;
	}
	const opResult = await stratOnepagerApi.getStratOnepagerForSerieUsingGET({ serieId });
	if ('error' in opResult) {
		return undefined;
	}
	thunkAPI.dispatch(setOnepagerHash(opResult.data));
	return {
		data: opResult.data,
		hierarchyOnePagers: [opResult.data as JsonStratOnepagerInfo],
	}
});

export const saveOnePager = createAsyncThunk('onepager/save', async (status: JsonStratOnepagerStatusEnum | undefined, thunkAPI) => {

	const state = thunkAPI.getState() as RootState;
	const onePager = state.strategy.onepager.data;
	const hash = state.strategy.hash;

	if (onePager) {
		const newOnePager = { ...fixOnePagerData(onePager), status: status || onePager.status };
		const newHash = JSON.stringify(newOnePager);
		console.log({ hash, newHash });
		if (newHash !== hash) {
			const result = await thunkAPI.dispatch(forceSaveOnePager(newOnePager));
			if ('error' in result) {
				console.error('Error while saving One-Pager ...');
				return false;
			} else {
				console.log('One-Pager saved ...');
				return true;
			}

		} else {
			console.log('One-Pager allready saved ...');
			return true;
		}
	}
	return true;
});

export const forceSaveOnePager = createAsyncThunk('onepager/force-save', async (onepager: JsonStratOnepager , thunkApi) => {
	return await stratOnepagerApi.saveStratOnepagerUsingPOST({ json: onepager });
});

export const fetchPillars = createAsyncThunk('onepager/pillars', async (params: GetStratPillarListUsingGETRequest , thunkApi) => {
	return await stratPillarApi.getStratPillarListUsingGET({ ...params, rows: 10000 });
});

export const fetchEnabler = createAsyncThunk('onepager/enabler', async (enablerId: number , thunkApi) => {
	return await stratEnablerApi.getStratEnablerUsingGET({enablerId});
});

export const fetchEnablerInfo = createAsyncThunk('onepager/enabler-info', async (enablerId: number , thunkApi) => {
	return await stratEnablerApi.getStratEnablerInfoUsingGET({enablerId});
});

export const fetchComments = createAsyncThunk('onepager/fetch-comments', async (onepagerId: number , thunkApi) => {
	return stratCommentApi.getStratCommentListUsingGET({ onepagerId, rows: 10000 });
});

export const saveComment = createAsyncThunk('onepager/save-comment', async (comment: JsonStratComment , thunkApi) => {
	return stratCommentApi.saveStratCommentUsingPOST({ json: comment });
});

interface DashboardOnePagers {
	type: OnepagersMenuEnum;
	param: any;
	onePagers?: JsonStratOnepagerInfo[];
}

export const setDashboardOnePagersType = createAsyncThunk('onepager/set-onepagers-type', async (params: { type: OnepagersMenuEnum, param?: any }, thunkApi): Promise<DashboardOnePagers> => {
	const { context } = (thunkApi.getState() as RootState).strategy;
	console.log({ type: params.type, params: params.param, context });
	const strategyId = context.strategy?.strategyId;
	let onePagers: JsonStratOnepagerInfo[] = [];

	if (params.type === OnepagersMenuEnum.Favourite && strategyId) {
		const { user } = (thunkApi.getState() as RootState).auth;
		const favouriteOnepagerIds = user?.userData?.favouriteOnepagerIds || [];
		if (favouriteOnepagerIds.length) {
			const result = await stratOnepagerApi.getStratOnepagerListUsingGET({ onepagerIds: favouriteOnepagerIds, strategyId });
			if (!('error' in result)) {
				onePagers = result.data || [];
			}
		}
	}

	if (params.type === OnepagersMenuEnum.LastVisited && strategyId) {
		const { user } = (thunkApi.getState() as RootState).auth;
		const recentOnepagerIds = user?.userData?.recentOnepagerIds || [];
		if (recentOnepagerIds.length) {
			const result = await stratOnepagerApi.getStratOnepagerListUsingGET({ onepagerIds: recentOnepagerIds, strategyId });
			if (!('error' in result)) {
				// sort by my settings
				onePagers = recentOnepagerIds.map((id: number) => result.data?.find((op: JsonStratOnepagerInfo) => op.onepagerId === id)).filter((op: JsonStratOnepagerInfo) => op);
			}
		}
	}

	if (params.type === OnepagersMenuEnum.All && strategyId) {
		const result = await stratOnepagerApi.getStratOnepagerListUsingGET({ strategyId });
		if (!('error' in result)) {
			onePagers = result.data || [];
		}
	}

	if (params.type === OnepagersMenuEnum.MyLevel && strategyId) {
		const { user } = (thunkApi.getState() as RootState).auth;
		const result = await stratOnepagerApi.getStratOnepagerListUsingGET({ pivotMode: GetStratOnepagerListUsingGETPivotModeEnum.SameLevel, strategyId, pivotUserId: user?.userId });
		if (!('error' in result)) {
			onePagers = result.data || [];
		}
	}

	if (params.type === OnepagersMenuEnum.Position && strategyId) {
		const result = await stratOnepagerApi.getStratOnepagerListUsingGET({ strategyId, positionId: params.param });
		if (!('error' in result)) {
			onePagers = result.data || [];
		}
	}

	if (params.type === OnepagersMenuEnum.CompanyPosition && strategyId) {
		const result = await stratOnepagerApi.getStratOnepagerListUsingGET({ strategyId, companyPositionId: params.param });
		if (!('error' in result)) {
			onePagers = result.data || [];
		}
	}

	return {
		type: params.type,
		param: params.param,
		onePagers
	}
});

export const refreshOnePagers= createAsyncThunk('onepager/set-onepagers-type', async (onePagersTypeChange: OnepagersMenuEnum | undefined, thunkApi): Promise<void> => {
	const { onePagersType, onePagersParam } = (thunkApi.getState() as RootState)?.strategy?.dashboard;
	if (onePagersType) {
		thunkApi.dispatch(setDashboardOnePagersType({ type: onePagersTypeChange || onePagersType, param: onePagersParam }));
	}
});

export const refreshFavouriteOnePagers = createAsyncThunk('onepager/refresh-favourite-onepagers', async (_: void, thunkApi): Promise<JsonStratOnepagerInfo[]> => {
	const {context} = (thunkApi.getState() as RootState).strategy;
	const strategyId = context.strategy?.strategyId;
	let onePagers: JsonStratOnepagerInfo[] = [];
	const {user} = (thunkApi.getState() as RootState).auth;
	const favouriteOnepagerIds = user?.userData?.favouriteOnepagerIds || [];
	if (favouriteOnepagerIds.length) {
		const result = await stratOnepagerApi.getStratOnepagerListUsingGET({
			onepagerIds: favouriteOnepagerIds,
			strategyId
		});
		if (!('error' in result)) {
			onePagers = result.data || [];
		}
	}
	return onePagers;
});


export type StrategyState = {
	context: {
		strategy?: JsonStrategy;
		company?: JsonCompany;
		companyPositions: JsonPoplistItem[];
		companyDepartments: JsonPoplistItem[];
	},
	dashboard: {
		hierarchyOnePagers: JsonStratOnepagerInfo[];
		subtreeOnePagers: JsonStratOnepagerInfo[];
		myLevelOnePagers: JsonStratOnepagerInfo[];
		favouriteOnePagers: JsonStratOnepagerInfo[];
		allOnePagers: JsonStratOnepagerInfo[];
		onePagersType?: OnepagersMenuEnum;
		onePagersParam?: any;
		onePagers?: JsonStratOnepagerInfo[];
	},
	onepager: {
		hierarchyOnePagers: JsonStratOnepagerInfo[];
		data?: JsonStratOnepager;
		comments?: JsonStratCommentInfo[];
		activeIndex?: OnepagerEntityIndex;
	};
	hash?: string;
	loading: string[];
}

const initialState: StrategyState = {
	context: {
		companyPositions: [],
		companyDepartments: []
	},
	dashboard: {
		hierarchyOnePagers: [],
		subtreeOnePagers: [],
		myLevelOnePagers: [],
		allOnePagers: [],
		favouriteOnePagers: [],
		onePagersType: OnepagersMenuEnum.Favourite,
		onePagersParam: undefined,
		onePagers: []
	},
	onepager: {
		hierarchyOnePagers: [],
		comments: [],
		data: undefined,
		activeIndex: undefined,
	},
	hash: undefined,
	loading: []
};

export const strategySlice = createSlice({
	name: 'strategy',
	initialState,
	reducers: {
		addOnepagerEntity: (state, action: PayloadAction<OnepagerEntityIndex>) => {
			state.onepager.data = processAddOnePagerEntity(state.onepager.data!, action.payload);
		},
		updateOnepagerEntity: (state, action: PayloadAction<{ index: OnepagerEntityIndex, data: string }>) => {
			state.onepager.data = processChangeOnePagerEntity(state.onepager.data!, action.payload.index, action.payload.data);
		},
		deleteOnepagerEntity: (state, action: PayloadAction<OnepagerEntityIndex>) => {
			state.onepager.data = processDeleteOnePagerEntity(state.onepager.data!, action.payload);
		},
		setOnepagerActiveIndex: (state, action: PayloadAction<OnepagerEntityIndex|undefined>) => {
			state.onepager.activeIndex = action.payload;
		},
		setOnepagerStatus: (state, action: PayloadAction<JsonStratOnepagerStatusEnum|undefined>) => {
			state.onepager.data!.status = action.payload;
		},
		setOnepagerHash: (state, action: PayloadAction<JsonStratOnepager|undefined>) => {
			state.hash = JSON.stringify(fixOnePagerData(action.payload));
		},
		setOnepagerParentPillar: (state, action: PayloadAction<{ index: OnepagerEntityIndex, data: Partial<JsonStratPillar> }>) => {
			state.onepager.data = processUpdateOnePagerParentPillar(state.onepager.data!, action.payload.index, action.payload.data);
		},
	},
	extraReducers: builder => {

		builder.addCase(fetchStrategyContext.pending, (state, action) => {
			return ({ ...state, loading: [...state.loading, 'context' ] })
		});
		builder.addCase(fetchStrategyContext.rejected, (state, action) => {
			return ({ ...state, loading: state.loading.filter(action => action !== 'context') })
		});
		builder.addCase(fetchStrategyContext.fulfilled, (state, action) => {
			return ({
				...state,
				context: action.payload,
				loading: state.loading.filter(action => action !== 'context')
			})
		});


		builder.addCase(fetchDashboardOnepagers.pending, (state, action) => {
			const pivotMode = action.meta.arg.pivotMode === GetStratOnepagerListUsingGETPivotModeEnum.Dashboard ? 'hierarchy' : 'subtree';
			return ({ ...state, loading: [...state.loading, pivotMode ] })
		});
		builder.addCase(fetchDashboardOnepagers.rejected, (state, action) => {
			const pivotMode = action.meta.arg.pivotMode === GetStratOnepagerListUsingGETPivotModeEnum.Dashboard ? 'hierarchy' : 'subtree';
			return ({ ...state, loading: state.loading.filter(action => action !== pivotMode) })
		});
		builder.addCase(fetchDashboardOnepagers.fulfilled, (state, action) => {
			const pivotMode = action.meta.arg.pivotMode === GetStratOnepagerListUsingGETPivotModeEnum.Dashboard ? 'hierarchy' : 'subtree';
			if (action.meta.arg.pivotMode === GetStratOnepagerListUsingGETPivotModeEnum.Dashboard) {
				action.payload.data?.sort((a, b) => (a.userPath || '').split('/').length > (b.userPath || '').split('/').length ? 1 : -1);
			}
			return ({
				...state,
				dashboard: {
					...state.dashboard,
					hierarchyOnePagers: action.meta.arg.pivotMode === GetStratOnepagerListUsingGETPivotModeEnum.Dashboard ? (action.payload as any).data : state.dashboard.hierarchyOnePagers,
					subtreeOnePagers: action.meta.arg.pivotMode === GetStratOnepagerListUsingGETPivotModeEnum.DrillDown ? (action.payload as any).data : state.dashboard.subtreeOnePagers,
					myLevelOnePagers: action.meta.arg.pivotMode === GetStratOnepagerListUsingGETPivotModeEnum.SameLevel ? (action.payload as any).data : state.dashboard.myLevelOnePagers,
					favouriteOnePagers: (!action.meta.arg.pivotMode && action.meta.arg.onepagerIds?.length) ? (action.payload as any).data : state.dashboard.favouriteOnePagers,
					allOnePagers: (!action.meta.arg.pivotMode && !action.meta.arg.onepagerIds?.length ) ? (action.payload as any).data : state.dashboard.allOnePagers
				},
				loading: state.loading.filter(action => action !== pivotMode)
			})
		});

		builder.addCase(fetchOnePager.pending, (state, action) => {
			return ({ ...state, loading: [...state.loading, 'onepager' ] })
		});
		builder.addCase(fetchOnePager.rejected, (state, action) => {
			return ({ ...state, loading: state.loading.filter(action => action !== 'onepager') })
		});
		builder.addCase(fetchOnePager.fulfilled, (state, action) => {
			return ({
				...state,
				onepager: {
					...state.onepager,
					...action.payload
				},
				hash: JSON.stringify(fixOnePagerData(action.payload?.data)),
				loading: state.loading.filter(action => action !== 'onepager')
			})
		});

		builder.addCase(fetchSerieOnePager.pending, (state, action) => {
			return ({ ...state, loading: [...state.loading, 'serie-onepager' ] })
		});
		builder.addCase(fetchSerieOnePager.rejected, (state, action) => {
			return ({ ...state, loading: state.loading.filter(action => action !== 'serie-onepager') })
		});
		builder.addCase(fetchSerieOnePager.fulfilled, (state, action) => {
			return ({
				...state,
				onepager: {
					...state.onepager,
					...action.payload
				},
				hash: JSON.stringify(fixOnePagerData(action.payload?.data)),
				loading: state.loading.filter(action => action !== 'serie-onepager')
			})
		});

		builder.addCase(fetchComments.pending, (state, action) => {
			return ({ ...state, loading: [...state.loading, 'comments' ] })
		});
		builder.addCase(fetchComments.rejected, (state, action) => {
			return ({ ...state, loading: state.loading.filter(action => action !== 'comments') })
		});
		builder.addCase(fetchComments.fulfilled, (state, action) => {
			return ({
				...state,
				onepager: {
					...state.onepager,
					comments: action.payload.data
				},
				loading: state.loading.filter(action => action !== 'comments')
			});
		});

		builder.addCase(forceSaveOnePager.pending, (state, action) => {
			return ({ ...state, loading: [...state.loading, 'save-onepager' ] })
		});
		builder.addCase(forceSaveOnePager.rejected, (state, action) => {
			return ({ ...state, loading: state.loading.filter(action => action !== 'save-onepager') })
		});
		builder.addCase(forceSaveOnePager.fulfilled, (state, action) => {
			console.log({ payload: action.payload });
			return ({
				...state,
				onepager: {
					...state.onepager,
					...action.payload
				},
				hash: JSON.stringify(fixOnePagerData(action.payload?.data)),
				loading: state.loading.filter(action => action !== 'save-onepager')
			})
		});

		builder.addCase(setDashboardOnePagersType.fulfilled, (state, action) => {
			console.log({ payload: action.payload });
			const onePagersType = action.payload?.type || state.dashboard?.onePagersType;
			const onePagers = action.payload?.onePagers || state.dashboard?.onePagers || [];
			return ({
				...state,
				dashboard: {
					...state.dashboard,
					onePagersType,
					onePagersParam: action.payload?.param,
					onePagers,
					favouriteOnePagers: onePagersType === OnepagersMenuEnum.Favourite ? onePagers : state.dashboard.favouriteOnePagers
				},
			})
		});

		builder.addCase(refreshFavouriteOnePagers.fulfilled, (state, action) => {
			return ({
				...state,
				dashboard: {
					...state.dashboard,
					favouriteOnePagers: action.payload
				},
			})
		});

	}
});

export const strategyReducer = strategySlice.reducer;
export const { addOnepagerEntity, updateOnepagerEntity, deleteOnepagerEntity, setOnepagerActiveIndex, setOnepagerStatus, setOnepagerParentPillar, setOnepagerHash } = strategySlice.actions;
